import { useEffect, useState } from 'react'
import { BiCompass, BiSearch, BiX } from 'react-icons/bi'
import { HiBadgeCheck, HiCalendar } from 'react-icons/hi'
import { ExploreSearch } from '../../components/SearchBar'
import SectionCard from '../../components/SectionCard'
import { useApiContext } from '../../utilis/ApiContext'
import {
   useCategoryModalContext,
   useExpoloreModalContext,
   useNavContext,
} from '../../utilis/Context'
import { randomKey, structureEventsByDate } from '../../utilis/functions'

export default function Explore() {
   // const { changeDate } = useExpoloreModalContext()
   const { refreshEvents } = useApiContext()
   const { handler } = useNavContext()
   const [search, setSearch] = useState(false)
   const handleSearch = () => {
      setSearch(!search)
   }

   useEffect(() => {
      window.scrollTo(0, 0)
      refreshEvents && refreshEvents()
      handler('Explorer')
   }, [])
   const { categories } = useApiContext()
   return (
      <div className="mb-2 mt-2 flex h-full  w-full max-w-screen-xl flex-col items-center md:mt-5">
         <div className="flex w-full max-w-screen-xl flex-row items-center gap-2 md:gap-4">
            <div className="flex h-full w-full flex-row gap-2 md:gap-4">
               <div className="items- flex w-full flex-col flex-wrap gap-4 rounded-xl bg-white p-4 dark:bg-dark-400 lg:w-8/12  lg:gap-6">
                  <div className="flex w-full flex-col justify-center gap-1">
                     <div className="flex w-full flex-row items-center justify-between gap-2">
                        {!search && (
                           <div className="flex w-full flex-row content-start items-center gap-2">
                              <BiCompass className="text-6 font-semibold text-gray-600 dark:text-darktext-white" />
                              <span className=" font-semibold text-gray-600 dark:text-darktext-white md:text-6">
                                 Explorez les Événements
                              </span>
                           </div>
                        )}
                        {search && (
                           <div className="flex min-h-16 w-full flex-row items-start justify-center gap-2">
                              <ExploreSearch />
                              <div className="pt-4 md:p-3">
                                 <BiX
                                    onClick={handleSearch}
                                    className="cursor-pointer text-6 font-semibold text-gray-600 dark:text-darktext-white"
                                 />
                              </div>
                           </div>
                        )}
                        {!search && (
                           <BiSearch
                              onClick={handleSearch}
                              size={34}
                              className="cursor-pointer rounded-full bg-primary-500 p-2 font-semibold text-white dark:bg-white dark:text-gray-600 "
                           />
                        )}
                     </div>
                  </div>
                  <div className="flex w-full flex-col justify-center gap-1 border-t pt-2 dark:border-dark">
                     <div className="flex flex-row items-center gap-2 font-semibold text-gray-600 dark:text-darktext-white">
                        <HiBadgeCheck className="text-5" />{' '}
                        <span className="">Par Catégorie </span>
                     </div>
                     <p className="p-1 text-slate-500 dark:text-slate-400 sm-max:text-3">
                        Cliquez sur une{' '}
                        <span className="font-semibold text-gray-600 dark:text-slate-200">
                           Catégorie
                        </span>{' '}
                        pour voir les événements disponible.
                     </p>
                     {categories ? (
                        <div className="mt-1 flex h-full w-full flex-row flex-wrap items-center gap-2 bg-white dark:bg-inherit  sm:rounded-xl">
                           {categories.map(({ id, name, total }) => (
                              <Category
                                 key={'cat' + randomKey() + id}
                                 name={name}
                                 total={total}
                              />
                           ))}
                        </div>
                     ) : (
                        <div className="mt-2 flex h-full w-full flex-row flex-wrap items-center gap-2 bg-white dark:bg-inherit  sm:rounded-xl">
                           <Category name={'Concert'} />
                           <Category name={'Sports'} />
                           <Category name={'Movies'} />
                           <Category name={'Comedy'} />
                           <Category name={'Party'} />
                           <Category name={'Conference'} />
                        </div>
                     )}
                  </div>

                  <div className="flex w-full flex-col justify-center gap-1 border-t pt-2 dark:border-dark">
                     <div className="flex flex-row items-center gap-2 font-semibold text-gray-600 dark:text-darktext-white">
                        <HiCalendar className="text-5" />{' '}
                        <span className="">Par Date </span>
                     </div>
                     <p className="p-1 text-slate-500 dark:text-slate-400 sm-max:text-3">
                        Cliquez sur la combinaison{' '}
                        <span className="font-semibold text-gray-600 dark:text-slate-200">
                           [année-mois-jour]
                        </span>{' '}
                        pour voir les événements disponible.
                     </p>
                     <SearchByDate />
                  </div>
               </div>
               <SponsoredEVents />
            </div>
         </div>
      </div>
   )
}

function SearchByDate() {
   const [currentYear, setYear] = useState()
   const [currentMonth, setMonth] = useState()
   const [currentDay, setDay] = useState()
   const [dates, setDates] = useState()
   const [selectedDate, setSelectedDate] = useState()
   const { changeDate } = useExpoloreModalContext()
   const { events } = useApiContext()
   // const dates = [
   //    {
   //       year: '2024',
   //       months: [
   //          {
   //             name: 'June',
   //             num: '06',
   //             dates: [10, 13, 20, 25],
   //          },
   //          {
   //             name: 'July',
   //             num: '07',
   //             dates: [1, 4, 13, 30],
   //          },
   //          {
   //             name: 'August',
   //             num: '08',
   //             dates: [3, 10, 14, 30],
   //          },
   //       ],
   //    },
   //    {
   //       year: '2025',
   //       months: [
   //          {
   //             name: 'Jan',
   //             num: '01',
   //             dates: [10, 13, 20, 25],
   //          },
   //          {
   //             name: 'Feb',
   //             num: '02',
   //             dates: [12, 13, 14, 15],
   //          },
   //          {
   //             name: 'March',
   //             num: '03',
   //             dates: [3, 10, 14, 30],
   //          },
   //       ],
   //    },
   // ]
   const changeYear = (date) => {
      setYear(date)
      setMonth()
   }
   const changeMonth = (date) => {
      setMonth(date)
      setDay()
   }
   const changeDay = (date) => {
      setDay(date)
   }

   useEffect(() => {
      dates && setYear(dates[0])
   }, [dates])
   useEffect(() => {
      if (events) {
         setDates(structureEventsByDate(events))
      }
   }, [events])

   useEffect(() => {
      if (currentDay) {
         setSelectedDate(
            currentYear?.year + '-' + currentMonth?.num + '-' + currentDay,
         )
         setDay()
      }
   }, [currentDay])

   useEffect(() => {
      // console.log(new Date(selectedDate))
      selectedDate && changeDate(selectedDate)
   }, [selectedDate])

   return (
      <>
         <div className="mt-2 flex h-full w-full flex-row flex-wrap items-center gap-2 bg-white dark:bg-inherit  sm:rounded-xl">
            {dates &&
               dates.map((date) => (
                  <Date
                     name={date?.year}
                     active={date?.year === currentYear?.year ? true : false}
                     onClick={() => changeYear(date)}
                     key={date?.year + 'date'}
                  />
               ))}
         </div>
         <div className="mt-2 flex h-full w-full flex-row flex-wrap items-center gap-2 bg-white dark:bg-inherit  sm:rounded-xl">
            {currentYear &&
               currentYear?.months?.map((month) => (
                  <Date
                     capitalize
                     name={month?.name}
                     active={month?.name === currentMonth?.name ? true : false}
                     key={month?.name + 'month'}
                     onClick={() => changeMonth(month)}
                  />
               ))}
         </div>
         <div className="mt-2 flex h-full w-full flex-row flex-wrap items-center gap-2 bg-white dark:bg-inherit  sm:rounded-xl">
            {currentMonth &&
               currentMonth?.dates?.map((day) => (
                  <Date
                     name={day}
                     active={day === currentDay ? true : false}
                     key={day + 'day'}
                     onClick={() => changeDay(day)}
                  />
               ))}
         </div>
      </>
   )
}
function Category({ name, icon, total }) {
   const { changeCategory } = useCategoryModalContext()
   return (
      <div
         onClick={() => changeCategory(name)}
         className="flex cursor-pointer items-center gap-1 rounded-full bg-bg px-3 py-1 font-semibold text-gray-600 hover:bg-primary hover:text-darktext-white  dark:bg-gray-700 dark:text-slate-200 dark:hover:bg-darktext dark:hover:text-dark "
      >
         {/* <span className="material-icons-round text-5 text-gray-600 dark:text-slate-400">
                  verified
               </span> */}
         <span className="">{name}</span>
         {total ? (
            <span className="rounded-full bg-primary px-2 text-white">
               {total}
            </span>
         ) : (
            <span className="rounded-full bg-pink-800 px-2 text-white">
               {total}
            </span>
         )}
      </div>
   )
}
function Date({ name, active, onClick, capitalize }) {
   return (
      <div
         onClick={onClick}
         className={
            active
               ? 'flex cursor-pointer items-center gap-1 rounded-full bg-primary px-3 py-1 font-semibold  text-darktext-white dark:bg-darktext dark:text-dark '
               : 'flex cursor-pointer items-center gap-1 rounded-full bg-bg px-3 py-1 font-semibold text-gray-600 hover:bg-primary hover:text-darktext-white  dark:bg-gray-700 dark:text-slate-200 dark:hover:bg-darktext dark:hover:text-dark '
         }
      >
         {/* <span className="material-icons-round text-5 text-gray-600 dark:text-slate-400">
                  event
               </span> */}
         <span className={capitalize && 'capitalize'}>{name}</span>
      </div>
   )
}

export function SponsoredEVents() {
   const { hots } = useApiContext()
   return (
      <div className="mt-2 hidden h-full w-4/12 flex-col flex-wrap items-center rounded-xl p-4  lg:flex">
         <div className="flex h-full w-full flex-col items-center justify-center gap-2">
            <div className="flex w-full flex-row content-start items-center gap-2">
               {/* <BiStar className="text-5 font-semibold text-gray-600 dark:text-darktext-white" /> */}

               <span className="rounded-xl bg-white px-3 py-1 font-semibold dark:bg-dark dark:text-darktext ">
                  ✨ Événements Populaires
               </span>
            </div>
            <div
               className="flex flex-col    gap-4 overflow-y-auto"
               style={{ height: '75dvh' }}
            >
               {hots &&
                  hots?.map((event) => (
                     <>
                        <SectionCard
                           event={event}
                           key={event?.dynamic_id + randomKey() + 'explhots'}
                        />
                     </>
                  ))}
            </div>
         </div>
      </div>
   )
}
/*
      {
         year: '2024',
         months: [
            {
               name: 'June',
               dates: [
                  {
                     date: '12',
                     events: [{ dynamic_id: 'event_12', name: 'Event 1' }],
                  },
                  {
                     date: '13',
                     events: [{ dynamic_id: 'event_12', name: 'Event 1' }],
                  },
                  {
                     date: '14',
                     events: [{ dynamic_id: 'event_12', name: 'Event 1' }],
                  },
               ],
            },
         ],
      }, 
      */
