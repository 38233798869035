import { useThemeMode } from 'flowbite-react'
import { useEffect, useState } from 'react'
import favicon from '../../assets/svg/favicon.svg'
import faviconDark from '../../assets/svg/favicon_dark.svg'
import LogoV2Light from '../../assets/svg/kwetuevent.svg'
import LogoV2Dark from '../../assets/svg/kwetuevent_dark.svg'
import LogoDark from '../../assets/svg/logo_dark.svg'
import LogoLight from '../../assets/svg/logo_light.svg'
export default function Logo({ size = '8', className }) {
   const [dark, setDark] = useState()
   const { mode } = useThemeMode()
   useEffect(() => {
      handleDark(mode)
   }, [mode])

   const handleDark = (mode) => {
      mode === 'dark' ? setDark(true) : setDark(false)
   }

   const [fontSize, setFontSize] = useState('text-8')
   const handleFont = (size) => {
      return 'text-' + size
   }
   useEffect(() => {
      setFontSize(handleFont(size))
   }, [size])
   return (
      <div
         className={
            'flex flex-col items-center justify-center gap-1 font-bold ' +
            fontSize +
            ' ' +
            className
         }
      >
         <img src={dark ? LogoDark : LogoLight} alt="" className="mb-1 h-20" />
         {/* <HiOutlineTicket className=" -rotate-45  mr-2 text-primary" />
         <span className=" text-6 font-semibold dark:text-slate-200">
            KwetuEvent
         </span> */}
      </div>
   )
}

export function LogoV2({ size = '8', className }) {
   const [dark, setDark] = useState()
   const { mode } = useThemeMode()
   useEffect(() => {
      handleDark(mode)
   }, [mode])

   const handleDark = (mode) => {
      mode === 'dark' ? setDark(true) : setDark(false)
   }

   return (
      <div
         className={
            'flex flex-row items-center justify-center gap-2 font-bold '
         }
      >
         <img
            src={dark ? faviconDark : favicon}
            alt=""
            // srcset=""
            className={'mb-1 h-8 w-8'}
         />
         <img
            src={dark ? LogoV2Dark : LogoV2Light}
            alt=""
            // srcset=""
            className={'mb-1 h-5 w-auto'}
         />
         {/* <HiOutlineTicket className=" -rotate-45  mr-2 text-primary" />
         <span className=" text-6 font-semibold dark:text-slate-200">
            KwetuEvent
         </span> */}
      </div>
   )
}
