import { useEffect, useState } from 'react'
import { styled } from 'styled-components'
import img from '../../assets/imgs/bg.png'

import { useThemeMode } from 'flowbite-react'
import { FaCalendar, FaEye } from 'react-icons/fa'
import { HiChevronLeft, HiLocationMarker } from 'react-icons/hi'
// import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Like } from '.'
import bg from '../../assets/imgs/bg.png'
import bg_dark from '../../assets/imgs/bg_dark.png'
import { appUrl, eventAssetsLink, supportNumber } from '../../utilis/ApiContext'
import { getCompact, getDate } from '../../utilis/functions'
import { NewBooking } from '../Booking'
import { Support } from '../MyTickets'
import Share from '../Share'

const Banner = styled.div`
   background-image: url('${(props) => props.bg}');
   background-position: center;
   background-size: contain;
   background-repeat: no-repeat;
   width: 100%;
`

export default function NewEventDetails({ event }) {
   const [booking, setBooking] = useState(false)
   // const navigate = useNavigate()
   const { mode } = useThemeMode()

   useEffect(() => {
      const handlePopState = (e) => {
         e.preventDefault()
         // Run your custom code here
         // console.log('Back button was pressed')
         // navigate(-1)
         setBooking && setBooking()
         // closeModal && closeModal()
         // Optionally, you can navigate to a different route
         // navigate('/some-other-route');
      }

      window.addEventListener('popstate', handlePopState)

      return () => {
         window.removeEventListener('popstate', handlePopState)
      }
   }, [])
   // window.scrollTo(0, 0)
   // use

   // useEffect(() => {
   //    return console.log('unmount')
   // }, [])

   return (
      <div className="flex h-full w-full flex-col items-center bg-white dark:bg-dark-400 sm:rounded-xl">
         <div className="flex w-full flex-col items-center dark:text-darktext-white">
            <Banner bg={mode === 'light' ? bg : bg_dark}>
               <div
                  style={{
                     backgroundImage:
                        'url(' +
                        (event ? eventAssetsLink + event?.banner : img) +
                        ')',
                  }}
                  className="flex h-80 w-full justify-center overflow-hidden rounded-xl  border bg-cover bg-center backdrop-blur-sm dark:border-none md:h-80"
               >
                  <Banner
                     bg={event ? eventAssetsLink + event?.banner : img}
                     className="flex items-start p-4 backdrop-blur-md"
                     // alt="article_image"
                     // srcSet=""
                  >
                     <div className="flex h-full w-full flex-col    justify-between gap-2 rounded-xl text-dark dark:text-darktext-white">
                        <div className="flex w-full items-center justify-between">
                           <Link
                              to={'/explore'}
                              className="flex cursor-pointer flex-row items-center gap-2 rounded-full bg-white px-2 py-1 font-bold text-gray-DEFAULT-600"
                           >
                              <HiChevronLeft className="text-5" />
                           </Link>

                           <span className="cursor-pointer rounded-full border border-white  bg-black bg-opacity-40 px-2 py-1 text-4 font-bold capitalize text-darktext-white  dark:border-none dark:text-slate-200">
                              {event ? event?.category?.name : 'concert'}
                           </span>

                           {/* <div
                              onClick={() => {
                                 // navigate(-1)
                                 setBooking()
                              }}
                              className="flex cursor-pointer flex-row items-center gap-2 rounded-full bg-white p-2 font-bold text-gray-DEFAULT-600"
                           >
                              <HiX className="text-5" />
                           </div> */}
                        </div>

                        <div className="flex flex-row items-end justify-between">
                           <span className="flex items-center justify-center gap-1 rounded-full bg-white px-2 py-1 dark:bg-dark-400 sm-max:text-3">
                              <FaEye className="text-4" />{' '}
                              {getCompact(event?.views)}
                           </span>
                           <span className="flex items-center justify-center gap-1 rounded-full bg-white px-2 py-1 dark:bg-dark-400 sm-max:text-3">
                              <Like event={event} />
                           </span>
                        </div>
                     </div>
                  </Banner>
               </div>
            </Banner>
            <>
               <div className="flex w-full flex-col gap-2 p-2 sm:p-4">
                  <div className="flex w-full flex-row items-center justify-between gap-1 p-4 md-max:p-2">
                     <div className="flex flex-col">
                        <span className="w-full font-bold dark:text-darktext-white md:text-5">
                           {event ? event?.title && event?.title : 'Event name'}
                        </span>
                        <span className="w-full italic dark:text-darktext-white md-max:text-3">
                           Organisé par{' '}
                           {event && event?.organizer && event?.organizer}
                        </span>
                     </div>
                     <Share
                        title={event?.title}
                        text={
                           'Découvrez : ' +
                           event?.title +
                           ' ce ' +
                           getDate(event?.date, 'long') +
                           ' sur KwetuEvent. \nNe manquez pas cet événement incroyable ! \n'
                        }
                        link={appUrl + '/events/' + event?.dynamic_id}
                     />
                  </div>
                  {event && event?.ticket_categories.length <= 0 && (
                     <div className="flex flex-col gap-2">
                        <div class="cursor pointer w-full rounded-md bg-bg px-3 py-2 font-serif text-3 text-red-700 dark:bg-dark dark:text-red-400">
                           Billetterie Indisponible pour l'instant
                        </div>
                     </div>
                  )}
                  {event && event?.ticket_categories.length > 0 && (
                     <NewBooking event={event} show={booking} />
                  )}
                  <div className="mb-16 flex flex-col gap-2 overflow-y-auto pb-2">
                     <div className="flex w-full flex-col gap-4 rounded-md bg-bg p-4 dark:bg-dark">
                        <div className="flex flex-row items-center justify-between gap-4">
                           <div className="flex flex-row items-center gap-4">
                              {/* <span className="rounded-lg bg-sky-DEFAULT-200 from-indigo-600 to-blue-700 p-4 text-sky-DEFAULT-600 opacity-60 dark:bg-gradient-to-r">
                                          <FaRegClock className="text-sky-DEFAULT-600 dark:text-darktext-white" />
                                       </span> */}
                              <span className="flex flex-col">
                                 <span className="capitalize text-gray-500 dark:text-slate-400 sm-max:text-3">
                                    {getDate(event?.date, 'long')}
                                 </span>
                                 <span className="font-semibold text-gray-800 dark:text-slate-200 sm:text-5">
                                    De {event?.start_time} à {event?.end_time}
                                 </span>
                                 <span className="font-serif text-gray-500 dark:text-slate-100 sm:text-4 sm-max:text-3">
                                    Ouverture des Portes à{' '}
                                    {event?.door_open_time}
                                 </span>
                              </span>
                           </div>
                           <span className="rounded-lg bg-sky-DEFAULT-200 from-indigo-600 to-blue-700 p-4 dark:bg-gradient-to-r">
                              <FaCalendar className="text-sky-DEFAULT-600 dark:text-darktext-white" />
                           </span>
                        </div>
                        <div className="flex flex-row items-center justify-between gap-4">
                           <div className="flex flex-row items-center gap-4">
                              {/* <span className="rounded-lg bg-sky-DEFAULT-200 from-indigo-600 to-blue-700 p-4 opacity-60 dark:bg-gradient-to-r">
                                          <HiLocationMarker className="text-sky-DEFAULT-600 dark:text-darktext-white" />
                                       </span> */}
                              <span className="flex flex-col">
                                 <span className="capitalize text-gray-400 sm-max:text-3">
                                    Venue :
                                 </span>
                                 <span className="font-semibold text-gray-800 dark:text-slate-200 sm:text-5">
                                    {event?.venue}
                                 </span>
                              </span>
                           </div>
                           <span className="rounded-lg bg-sky-DEFAULT-200 from-indigo-600 to-blue-700 p-4 dark:bg-gradient-to-r">
                              <HiLocationMarker className="text-sky-DEFAULT-600 dark:text-darktext-white" />
                           </span>
                        </div>
                     </div>
                     <div className="w-full px-2">
                        <span className="font-bold dark:text-slate-200">
                           Description :
                        </span>
                        <p className="text-slate-500 dark:text-slate-400 sm-max:text-3">
                           {event ? event?.description : event?.category?.name}
                        </p>
                     </div>
                     <div className="mb-2 hidden w-full px-2">
                        <span className="font-bold dark:text-slate-200">
                           Contacts de l'organisateur :
                        </span>
                        <div className="flex flex-col gap-2 py-2 md:flex-row">
                           <a
                              href={'https://wa.me/' + supportNumber}
                              target="_blank"
                              rel="noreferrer"
                              className="cursor pointer rounded-md bg-bg px-3 py-2 dark:bg-dark"
                           >
                              {supportNumber}
                           </a>
                           {/* <a class="rounded-md bg-dark px-3 py-2 ">
                                       {supportNumber}
                                    </a> */}
                        </div>
                        {/* <p className="text-slate-500 dark:text-slate-400 sm-max:text-3">
                                    {event
                                       ? event?.description
                                       : event?.category?.name}
                                 </p> */}
                     </div>
                     <Support
                        message={
                           "Bonjour KwetuEvent, j'ai besoin de plus d'information sur l'événement : " +
                           event?.title
                        }
                        contacts={event?.contacts}
                     />
                  </div>
               </div>
            </>
         </div>
      </div>
   )
}
