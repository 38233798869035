import { styled } from 'styled-components'

import { useThemeMode } from 'flowbite-react'
import { FaClock } from 'react-icons/fa'
import { HiCalendar, HiLocationMarker } from 'react-icons/hi'
import bg from '../../assets/imgs/bg.png'
import bg_dark from '../../assets/imgs/bg_dark.png'
import { eventAssetsLink, useApiContext } from '../../utilis/ApiContext'
import { useEventModalContext } from '../../utilis/Context'
import { getDate } from '../../utilis/functions'

const Banner = styled.div`
   background-image: url('${(props) => props.bg}');
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
`
export default function NextEvent() {
   // const event = next
   const { next } = useApiContext()
   const { changeEvent } = useEventModalContext()
   const { mode } = useThemeMode()

   // useEffect(() => {
   //    refreshNext && refreshNext()
   // }, [])

   return (
      // <div className=" w-full h-full flex flex-col justify-center hover:bg-bg bg-white dark:hover:bg-dark-400 dark:bg-dark-500 dark:border-none border rounded-xl hover:shadow-xs">

      // <Link to={'/events/' + next?.dynamic_id}>
      <Banner bg={mode === 'light' ? bg : bg_dark} className="rounded-xl">
         <Banner
            onClick={() => next && changeEvent(next)}
            bg={next?.banner && eventAssetsLink + next?.banner}
            className="h-60 w-full cursor-pointer rounded-xl p-2 shadow-xxs dark:shadow-none lg:h-72"
         >
            <Description
               title={next?.title}
               venue={next?.venue}
               date={next?.date}
               time={next?.start_time}
            />
         </Banner>
      </Banner>
      // </Link>
   )
}

// function Banner({img}) {
//     return(
//         <div >
//         </div>
//     )
// }

function Description({ title, date, venue, time }) {
   return (
      <div className="flex h-full w-full flex-col justify-between gap-2 rounded-xl p-2 dark:text-dark">
         <div className="flex items-start justify-between">
            {/* <span className="font-semibold dark:text-dark bg-darktext-white p-2 px-3 rounded-full inline">
               Prochain Evénement
            </span> */}
            <div className=" flex flex-row items-center gap-2 rounded-full border border-darktext-white bg-black bg-opacity-25 px-2 py-1 font-semibold text-darktext-white dark:border-dark dark:bg-darktext dark:bg-opacity-60 dark:text-dark sm-max:text-3">
               <HiCalendar /> <span>Prochain Événement</span>
            </div>
            <div className="flex flex-col items-center justify-center rounded-xl bg-gradient-to-r from-indigo-600 to-blue-700 px-4 py-1 text-center">
               <span className="text-6 font-bold text-darktext-white">
                  {date ? getDate(date).split(' ')[1] : '01'}
               </span>
               <span className="capitalize text-slate-200">
                  {date ? getDate(date).split(' ')[2] : 'Jan'}
               </span>
            </div>
         </div>
         <div className="flex flex-col gap-2 rounded-xl bg-white  p-4 dark:bg-dark dark:text-slate-200">
            <span className="font-bold sm:text-5">
               {title ? title : 'KwetuEvent'}
            </span>
            <div className="flex flex-row items-center gap-2">
               <div className="flex items-center gap-2 ">
                  <HiLocationMarker />
                  <span className="sm-max:text-3">
                     {venue ? venue : 'Venue'}
                  </span>
               </div>
               {date && (
                  <div className="flex flex-row items-center gap-2 ">
                     <FaClock />
                     <span className="sm-max:text-3">
                        {new Date(date + ' ' + time).getHours()} :{' '}
                        {new Date(date + ' ' + time).getMinutes() < 9
                           ? '0' + new Date(date + ' ' + time).getMinutes()
                           : new Date(date + ' ' + time).getMinutes()}
                     </span>
                  </div>
               )}
            </div>
         </div>
      </div>
   )
}

// function Description({ title, time, date }) {
//    return (
//       <div className="w-full rounded-b-xl p-4 flex flex-col gap-2 dark:text-darktext">
//          <span className="font-semibold"> {title ? title : 'Event'}</span>
//          <div className="flex gap-1 items-center">
//             <span className="material-icons-round">event</span>
//             <span className="text-4">
//                {date
//                   ? new Date(date).toLocaleDateString('fr')
//                   : 'Samedi, 23 Decembre 2023'}
//             </span>
//          </div>

//          <div className="flex justify-between items-center">
//             <div className="flex flex-col gap-1 items-center">
//                <span className="text-3 font-semibold">Time</span>
//                <span className="text-3">{time ? time : '13:00'}</span>
//             </div>
//             <div className="flex flex-col gap-1 items-center">
//                <span className="text-3 font-semibold">Statut</span>
//                <span className="text-3">
//                   {date ? new Date(date).toLocaleDateString('fr') : 'active'}
//                </span>
//             </div>
//             {/* <BtnBook /> */}
//          </div>
//       </div>
//    )
// }

// function BtnBook() {
//    return (
//       <span className="rounded-xl bg-primary px-10 py-1 text-center font-semibold text-white dark:bg-darktext-white dark:text-primary">
//          Reserver
//       </span>
//    )
// }
