// import { styled } from 'styled-components'
import { Modal } from 'flowbite-react'
import {
   useFormModalContext,
   usePopUpContext,
   useSessionContext,
} from '../../utilis/Context'
import Contact from '../Contact'
import Register from '../Register'
import ResetPassword from '../ResetPassword'
import SignUp from '../SignUp'

import { useEffect } from 'react'
import { HiChevronLeft, HiX } from 'react-icons/hi'
import Account from '../Account'
import MyTickets from '../MyTickets'
import Notification from '../Notification'
import Profile from '../Profile'

export default function View() {
   const { startSession } = useSessionContext()
   const { openPopUp } = usePopUpContext()
   const { loginModalState, closeModal, view, changeForm } =
      useFormModalContext()
   useEffect(() => {
      const handlePopState = (event) => {
         event.preventDefault()
         // Run your custom code here
         // console.log('Back button was pressed')
         // navigate(-1)
         // setBooking && setBooking()
         closeModal && closeModal()
         // Optionally, you can navigate to a different route
         // navigate('/some-other-route');
      }

      window.addEventListener('popstate', handlePopState)

      return () => {
         window.removeEventListener('popstate', handlePopState)
      }
   }, [])
   return (
      <Modal
         size="3xl"
         show={loginModalState}
         // dismissible={true}
         // className="min-h-120"
         // onClose={
         //    view !== 'menu' &&
         //    view !== 'notifications' &&
         //    view !== 'mes billets' &&
         //    view !== 'contactez nous'
         //       ? () => changeForm('menu')
         //       : closeModal
         // }
         popup
      >
         <div className="flex w-full items-center justify-center p-4">
            {view !== 'menu' &&
               view !== 'notifications' &&
               view !== 'mes billets' && (
                  <div
                     onClick={() => {
                        view === "vérification d'identité" ||
                        view === 'enregistrement'
                           ? changeForm('connexion')
                           : view !== 'menu' &&
                             view !== 'notifications' &&
                             view !== 'mes billets'
                           ? changeForm('menu')
                           : closeModal()
                     }}
                     className="hoverbg-gray-200 flex cursor-pointer flex-row items-center gap-2 rounded-full p-2 font-bold text-gray-DEFAULT-600 dark:text-slate-200 dark:hover:bg-gray-600"
                  >
                     <HiChevronLeft className="text-5" />
                  </div>
               )}
            <div className="flex w-full items-center justify-center">
               <span className="text-5 font-bold capitalize text-gray-600 dark:text-slate-200">
                  {view}
               </span>
            </div>
            <div
               onClick={() => {
                  closeModal()
               }}
               className="hoverbg-gray-200 flex cursor-pointer flex-row items-center gap-2 rounded-full p-2 font-bold text-gray-DEFAULT-600 dark:text-slate-200 dark:hover:bg-gray-600"
            >
               <HiX className="text-5" />
            </div>
         </div>

         <Modal.Body className="rounded-xl dark:bg-dark-400 sm-max:p-0 md:min-h-120">
            {view &&
               (view === 'connexion' ? (
                  <SignUp
                     // back =
                     startSession={startSession}
                     close={closeModal}
                     handler={changeForm}
                     openPopUp={openPopUp}
                  />
               ) : view === 'enregistrement' ? (
                  <Register
                     startSession={startSession}
                     close={closeModal}
                     handler={changeForm}
                     openPopUp={openPopUp}
                  />
               ) : view === 'contactez nous' ? (
                  <Contact openPopUp={openPopUp} />
               ) : view === "vérification d'identité" ? (
                  <ResetPassword handler={changeForm} openPopUp={openPopUp} />
               ) : view === 'notifications' ? (
                  <Notification />
               ) : view === 'menu' ? (
                  <Profile />
               ) : view === 'mon compte' ? (
                  <Account />
               ) : (
                  view === 'mes billets' && <MyTickets />
               ))}
         </Modal.Body>
      </Modal>
   )
}

// function Category({ name, icon }) {
//    return (
//       <CategoryModalContext.Consumer>
//          {({ changeCategory }) => (
//             <div
//                onClick={() => changeCategory(name)}
//                className="cursor-pointer flex gap-1 px-3 py-1 items-center dark:bg-darktext font-semibold hover:text-white hover:bg-primary rounded-full bg-bg"
//             >
//                <span className="material-icons-round text-5 ">verified</span>
//                <span>{name}</span>
//             </div>
//          )}
//       </CategoryModalContext.Consumer>
//    )
// }
