import { useThemeMode } from 'flowbite-react'
import { Suspense, useEffect, useState } from 'react'
import { ApiProvider, useApiContext } from '../../utilis/ApiContext'
import {
   CartProvider,
   CategoryModalProvider,
   EventModalProvider,
   ExploreModalProvider,
   FormModalProvider,
   NavProvider,
   PopUpProvider,
   SessionProvider,
} from '../../utilis/Context'
import Brand from '../Brand'
import Cart from '../Cart'
import Categories from '../Categories'
import Dates from '../Dates'
import EventDetails from '../EventDetails'
import Explore from '../Explore'
import Footer from '../Footer'
import InternetState from '../InternetState'
import Login from '../Login'
import NavBar from '../NavBar'
import PopUp from '../PopUp'

function AppLayout({ children }) {
   const {
      isLoadingNext,
      isLoadingHots,
      isLoadingEvents,
      events,
      eventError,
      refreshAll,
   } = useApiContext()

   const [serverError, setServerError] = useState(false)
   const [brand, setBrand] = useState(true)
   const { setMode } = useThemeMode()
   const [isLoading, setIsLoading] = useState(true)

   //timer
   useEffect(() => {
      const timer = setTimeout(() => {
         if (!isLoadingEvents) {
            if (eventError && !events) {
               setServerError(true)
            } else {
               setIsLoading(false)
               setBrand(false)
               setServerError(false)
            }
         }
      }, 3000)

      return () => clearTimeout(timer)
   }, [isLoadingNext, isLoadingHots, isLoadingEvents, eventError, events])
   //timer

   const refresh = () => {
      setBrand(true)
      refreshAll()
      const timer = setTimeout(() => {
         if (events && !eventError) {
            setIsLoading(false)
            setBrand(false)
         } else {
            setServerError(true)
         }
      }, 3000)
      setServerError()

      return () => clearTimeout(timer)
   }

   // useEffect(() => {
   //    // console.log(isLoadingNext, isLoadingHots, isLoadingEvents)
   //    if (!isLoadingEvents) {
   //       if (eventError && !events) {
   //          setServerError(true)
   //       } else {
   //          setBrand(false)
   //          setServerError(false)
   //       }
   //    }
   // }, [isLoadingNext, isLoadingHots, isLoadingEvents, eventError, events])

   useEffect(() => {
      const prefersDarkScheme = window.matchMedia(
         '(prefers-color-scheme: dark)',
      )
      const prefersLightScheme = window.matchMedia(
         '(prefers-color-scheme: light)',
      )

      const updateColorScheme = () => {
         if (prefersDarkScheme.matches) {
            setMode('dark')
         } else if (prefersLightScheme.matches) {
            setMode('light')
         } else {
            setMode('light')
         }
      }

      updateColorScheme()

      prefersDarkScheme.addEventListener('change', updateColorScheme)
      prefersLightScheme.addEventListener('change', updateColorScheme)

      return () => {
         prefersDarkScheme.removeEventListener('change', updateColorScheme)
         prefersLightScheme.removeEventListener('change', updateColorScheme)
      }
   }, [])

   return (
      <>
         {isLoading || brand || serverError ? (
            <Brand
               error={serverError}
               setError={setServerError}
               refreshAll={refresh}
            />
         ) : (
            <Suspense
               fallback={
                  <Brand
                     setError={setServerError}
                     error={serverError}
                     refreshAll={refresh}
                  />
               }
            >
               <div className="flex h-full min-h-[100dvh] w-full flex-col items-center gap-2 bg-white text-dark-400 dark:bg-dark-400 dark:text-darktext  sm:p-2 md-max:pt-2">
                  <NavBar />
                  {children}
                  <Footer />
                  <Login />
                  <Cart />
                  <EventDetails />
                  <Categories />
                  <Explore />
                  <Dates />
                  <PopUp />
               </div>
            </Suspense>
         )}
      </>
   )
}

export default function Layout({ children }) {
   return (
      <SessionProvider>
         <ApiProvider>
            <NavProvider>
               <EventModalProvider>
                  <CategoryModalProvider>
                     <ExploreModalProvider>
                        <FormModalProvider>
                           <PopUpProvider>
                              <CartProvider>
                                 <AppLayout>{children}</AppLayout>
                                 <InternetState />
                              </CartProvider>
                           </PopUpProvider>
                        </FormModalProvider>
                     </ExploreModalProvider>
                  </CategoryModalProvider>
               </EventModalProvider>
            </NavProvider>
         </ApiProvider>
      </SessionProvider>
   )
}
