// import { CustomFlowbiteTheme } from 'flowbite-react';
import { Carousel, useThemeMode } from 'flowbite-react'
import { assetsLink, useApiContext } from '../../utilis/ApiContext'
// import { localBanners } from '../../utilis/Data'
import banner_1 from '../../assets/imgs/banner_1.png'
import banner_2 from '../../assets/imgs/banner_2.png'
import bg from '../../assets/imgs/bg.png'
import bg_dark from '../../assets/imgs/bg_dark.png'
import NextEvent from '../NextEvent'

const customTheme = {
   control: {
      base: 'hidden',
      icon: 'hidden',
   },
}

export default function HomeBanner() {
   // const data = localBanners
   // const { next } = useApiContext()
   const { banners } = useApiContext()
   const { mode } = useThemeMode()

   return (
      <div className="flex w-full flex-col items-center gap-5 px-4 pt-4 md:flex-row">
         <div className="h-60 w-full sm:h-64 md:w-7/12 lg:w-8/12 xl:h-85">
            <Carousel
               slideInterval={5000}
               pauseOnHover
               theme={{ theme: customTheme }}
               className="rounded-lg bg-cover bg-center"
               style={{
                  backgroundImage:
                     mode === 'light'
                        ? 'url(' + bg + ')'
                        : 'url(' + bg_dark + ')',
               }}
            >
               <img className="" src={banner_1} alt="..." />
               <img className="" src={banner_2} alt="..." />
               {banners &&
                  banners?.length > 0 &&
                  banners.map((banner) => (
                     <img
                        className=""
                        src={assetsLink + banner?.img}
                        key={banner?.img}
                        alt="..."
                     />
                  ))}
            </Carousel>
         </div>
         {/* {next && ( */}
         <div className="flex w-full flex-col gap-4 md:w-5/12 lg:w-4/12">
            <NextEvent />
         </div>
         {/* )} */}
      </div>
   )
}
