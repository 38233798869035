import { useEffect, useState } from 'react'
// import { styled } from 'styled-components'
import { Button, Modal, TextInput, useThemeMode } from 'flowbite-react'
import { useForm } from 'react-hook-form'
import { CiBarcode } from 'react-icons/ci'
import {
   HiCheckCircle,
   HiChevronDown,
   HiChevronLeft,
   HiChevronUp,
   HiEmojiSad,
   HiLockClosed,
   HiOutlineTicket,
   HiPhone,
   HiShoppingCart,
   HiTrash,
   HiXCircle,
} from 'react-icons/hi'
import approved from '../../assets/svg/approved.svg'
import confirm from '../../assets/svg/confirm.svg'
import confirm_dark from '../../assets/svg/confirm_dark.svg'
import rejected from '../../assets/svg/rejected.svg'
import { apiLink } from '../../utilis/ApiContext'
import {
   getLoggedUser,
   useCartContext,
   useEventModalContext,
   useFormModalContext,
   usePopUpContext,
} from '../../utilis/Context'
import { usePost } from '../../utilis/CustomHooks'
import { MyLoader } from '../Loader'
import { DottedLoader } from '../Posts'
import PaymentMethod, { CreditCard, MobileMoney } from './PaymentMethod'

export function getTotal(cartTotal) {
   if (cartTotal && cartTotal.length > 1) {
      let totale = ''
      cartTotal.forEach(({ total, currency }, index) => {
         if (index + 1 < cartTotal.length) {
            totale += total && currency && total + ' ' + currency + ' & '
         } else {
            totale += total && currency && total + ' ' + currency
         }
      })
      // console.log(totale)
      return totale
   } else {
      return (
         cartTotal &&
         cartTotal.length > 0 &&
         cartTotal[0].total + ' ' + cartTotal[0].currency
      )
   }
}

export default function Cart() {
   // const event = null
   const { modalState, closeModal, cart, clearCart, cartTotal } =
      useCartContext()
   const [payment, setPayment] = useState(false)
   const [title, setTitle] = useState({
      title: 'Mon Panier',
      icon: HiShoppingCart,
   })

   const openPayment = () => {
      setPayment(true)
   }
   const closePayment = () => {
      setPayment(false)
      setTitle({
         title: 'Mon Panier',
         icon: HiShoppingCart,
      })
   }

   return (
      <Modal size="3xl" show={modalState} onClose={closeModal} popup>
         <Modal.Body className="bottom-0 rounded-xl p-0 dark:bg-dark-400 sm-max:p-0">
            <div className="flex h-full  w-full flex-col items-center rounded-xl bg-white dark:bg-dark-400">
               <div className="flex w-full items-center justify-between rounded-t-xl border-b px-4 py-2 text-dark dark:border-none dark:bg-dark dark:text-darktext ">
                  {payment ? (
                     <HiChevronLeft
                        size={32}
                        onClick={() => {
                           payment ? closePayment() : closeModal()
                        }}
                        className="material-icons-round cursor-pointer justify-end rounded p-1 text-dark hover:bg-darktext dark:text-darktext dark:hover:bg-dark-400"
                     />
                  ) : (
                     <span></span>
                  )}
                  <span className="flex items-center gap-2 font-semibold">
                     <title.icon size={28} />
                     {title.title}
                  </span>

                  {!payment ? (
                     <HiXCircle
                        size={32}
                        onClick={() => {
                           payment ? closePayment() : closeModal()
                        }}
                        className="material-icons-round cursor-pointer justify-end rounded p-1 text-dark hover:bg-darktext dark:text-darktext dark:hover:bg-dark-400"
                     />
                  ) : (
                     <span></span>
                  )}

                  {/* {!payment ? (
                     <span
                        onClick={() => clearCart()}
                        className="material-icons-round cursor-pointer justify-end rounded-full p-2 text-gray-600 hover:bg-darktext hover:text-red-700 dark:text-darktext dark:hover:bg-dark-400"
                     >
                        delete
                     </span>
                  ) : (
                     <span
                        // onClick={() => clearCart()}
                        className="material-icons-round cursor-pointer justify-end rounded-full p-2 text-dark hover:bg-darktext hover:text-red-700 dark:text-darktext dark:hover:bg-dark-400"
                     ></span>
                  )} */}
               </div>
               {!payment ? (
                  <div className="flex flex-col items-center gap-2 px-4 pb-4 pt-4 md:px-6 md:pb-6 ">
                     <div
                        className={
                           ' bg-bd flex  w-full flex-col items-start gap-2 rounded-md bg-bg p-2 dark:bg-dark md:p-4'
                        }
                     >
                        {cart.map(({ event, tickets, ticket, id }) => (
                           <BookedTicket
                              id={id}
                              event={event}
                              spots={tickets}
                              ticket={ticket}
                              key={id}
                           />
                        ))}
                        <div className="flex w-full items-center justify-around border-b border-dashed"></div>
                        <div className="flex w-full items-center justify-around font-serif">
                           <span className="mt-2 px-2 text-center font-semibold text-dark-400 dark:text-darktext md:text-4">
                              Total à payer :
                           </span>
                           <span className="mt-2 flex flex-col gap-1 px-2  text-center font-semibold text-dark-400 dark:text-darktext md:gap-2 md:text-5">
                              {cartTotal && getTotal(cartTotal)}
                           </span>
                        </div>
                     </div>
                     <div className="flex w-full flex-col items-center justify-center gap-2">
                        <Button
                           onClick={() => openPayment()}
                           disabled={cartTotal.length <= 0}
                           color="blue"
                           className="w-full bg-primary hover:bg-dark"
                        >
                           Passer au Paiement
                        </Button>
                        <Button
                           onClick={() => closeModal()}
                           color="gray"
                           className="w-full border-none text-primary dark:bg-dark dark:text-darktext"
                        >
                           Acheter d'autre tickets
                        </Button>
                        <span className="mt-2 w-10/12 px-2 text-center text-3 italic text-dark-400 dark:text-darktext md:text-4">
                           Tout ce qui se trouve dans votre panier sera effacé
                           lorsque vous quitterez cette page.
                        </span>
                     </div>
                  </div>
               ) : (
                  <Payment
                     cart={cart}
                     cartTotal={cartTotal}
                     setTitle={setTitle}
                     close={() => {
                        clearCart()
                        closeModal()
                     }}
                  />
               )}
            </div>
         </Modal.Body>
      </Modal>
   )
}

function BookedTicket({ event, spots, id, ticket, minimal }) {
   const { deleteFromCart } = useCartContext()
   return (
      <div className="flex w-full flex-row items-center gap-2">
         <div className="flex w-full cursor-pointer flex-row items-center justify-between gap-2 rounded-xl bg-bg dark:bg-dark sm:p-2">
            {!minimal && (
               <HiOutlineTicket className=" mx-2 -rotate-45 text-6 text-blue-500 md:text-8" />
            )}
            <div className="flex w-full flex-col items-start text-3 text-dark dark:text-darktext-white">
               <span className="font-serif font-semibold text-dark-400 dark:text-gray-400">
                  {event.title}
               </span>
               <div className="gap- flex w-full flex-row items-center justify-between font-serif">
                  <span className="text-3 font-semibold text-dark-400 dark:text-darktext sm:text-4">
                     Ticket {ticket.type} {ticket.price + ' ' + ticket.currency}{' '}
                     x {spots.length} :{' '}
                     {/* <span className="text-primary">
                     {' '}
                     {'(' +
                        spots.length +
                        ' Ticket' +
                        (spots.length > 1 ? 's' : '') +
                        ')'}
                  </span> */}
                  </span>
                  <span className="font-semibold text-dark-400 dark:text-darktext sm:text-4">
                     {+ticket.price * spots.length + ' ' + ticket.currency}
                  </span>
               </div>
            </div>
         </div>
         {!minimal && (
            <HiTrash
               onClick={() => deleteFromCart(id)}
               className="material-icons-round cursor-pointer justify-end rounded-full text-gray-600 hover:text-red-700 dark:text-darktext dark:hover:text-red-700"
            />
         )}
      </div>
   )
}

export function CustomPopUp({ children, state, close, title, popup }) {
   return (
      state === true && (
         <div className="position fixed left-0 top-0 z-100 flex h-[100dvh] w-screen items-center justify-center bg-black bg-opacity-40 p-4">
            <div className="flex w-100 flex-col gap-2 rounded-xl bg-darktext-white pb-4 pl-2 pt-2 dark:bg-dark-400 md:w-120">
               {!popup && (
                  <div className="flex h-10 w-full items-center justify-between pr-4">
                     <HiChevronLeft
                        onClick={() => close && close()}
                        className="material-icons-round cursor-pointer justify-end rounded p-1 text-8 text-dark hover:bg-darktext dark:text-darktext dark:hover:bg-dark-400"
                     />
                     <span className="font-semibold">{title}</span>
                     <span></span>
                  </div>
               )}
               {children}
            </div>
         </div>
      )
   )
}

function Payment({ cart, cartTotal, setTitle, close }) {
   const [method, setMethod] = useState()
   const { openPopUp } = usePopUpContext()
   // const [checkout, setCheckOut] = useState({})
   // const [reserve, setReserve] = useState({})
   // const [methods, isLoadingMethods] = useFetch(
   //    apiLink + '/api/payments/methods',
   // )
   const [response, error, isLoading, setPost] = usePost(
      apiLink + '/api/events/reserve_many',
   )

   const handleMethod = (methd) => {
      setMethod(methd)
   }

   const { closeModal } = useFormModalContext()
   const { changeEvent } = useEventModalContext()

   const handlePost = () => {
      const data = []
      const code =
         'KERSV' +
         Math.floor(Math.random() * 1000) +
         new Date().getMilliseconds().toString()
      cart.forEach(({ event, ticket, tickets, email }) => {
         tickets.forEach(({ spot, name }) => {
            data.push({
               event_id: event.id,
               code: code,
               name_holder: name,
               ticket_category_id: ticket.id,
               user_id: getLoggedUser().user.id,
               spot: spot,
            })
         })
      })

      setPost({ array: data })
   }
   const handleReserve = () => {
      // setReserve(true)
      openPopUp({
         title: 'Reservation',
         content:
            "Vous êtes sur le point d'effectuer une réservation des éléments de votre panier, le montant total est de " +
            getTotal(cartTotal),
         decline: {
            title: 'Annuler',
            // handler: closeParent,
         },
         accept: {
            title: 'Réserver',
            handler: () => {
               handlePost()
            },
         },
      })
   }

   useEffect(() => {
      if (response) {
         openPopUp({
            title: '✅ Réservation',
            content: 'Votre réservation a été effectuée avec succès',
            default: {
               title: '👊🏾',
               handler: () => {
                  close()
                  closeModal()
                  changeEvent()
               },
            },
         })
      }
      if (error) {
         // console.log(error)
         openPopUp({
            title: "Erreur d'authentification",
            content: error.message,
            decline: {
               title: 'Essayer à nouveau',
            },
         })
      }
   }, [response, error])

   useEffect(() => {
      return setTitle({
         title: 'Mode de Paiement',
         icon: 'payment',
      })
   }, [])
   return method ? (
      <Checkout
         method={method}
         setTitle={setTitle}
         close={close}
         cart={cart}
         cartTotal={cartTotal}
      />
   ) : (
      <div className="flex flex-col items-center gap-2 px-4 pb-4 md:px-6 md:pb-6 ">
         <span className="mt-2 px-2 text-center  text-dark-400 dark:text-darktext md:text-4">
            Choisissez ci-dessus le mode de paiement que vous souhaitez utiliser
         </span>
         <div className="justify-left flex w-full flex-wrap items-center gap-4 border-b border-slate-400 p-2 pb-4">
            <PaymentMethod handleMethod={handleMethod} />
            {/* {isLoadingMethods && <MyLoader />} */}
            {/* {methods &&
               methods.length > 0 &&
               methods.map(({ id, name, number, cover }) => (
                  <Method
                     onClick={handleMethod}
                     icon={assetsLink + 'methods/' + cover}
                     name={name}
                     number={number}
                     id={id}
                     key={id + name + cover}
                  />
               ))} */}
            {/* <Method
               onClick={handleMethod}
               icon={orange}
               name={'Orange Money'}
            />
            <Method onClick={handleMethod} icon={mpesa} name={'M-Pesa'} /> */}
            {/* <Method
               onClick={handleMethod}
               icon={mastercard}
               name={'mastercard'}
            />
            <Method onClick={handleMethod} icon={visa} name={'visa'} /> */}
         </div>
         <div className="flex w-full flex-row items-center justify-center gap-2 p-2 text-dark-400 dark:text-darktext">
            <HiLockClosed size={18} />
            <span>Paiement sécurisé</span>
         </div>
         {isLoading && (
            <div className="fixed left-0 top-0 z-10 flex h-screen w-screen items-center justify-center bg-black bg-opacity-40">
               <MyLoader />
            </div>
         )}
         <div className="hidden w-full flex-col items-center justify-center gap-2 p-2 md:p-4">
            <span className="text-center font-semibold text-dark-400 dark:text-darktext md:text-4">
               ou
            </span>
            <Button
               onClick={() => handleReserve()}
               color="gray"
               className="w-full border border-primary text-primary dark:border-none dark:bg-dark dark:text-darktext"
            >
               Réservez maintenant et payez plus tard
            </Button>
         </div>
      </div>
   )
}

// function Method({ id, name, icon, onClick, number }) {
//    return (
//       <div
//          onClick={() =>
//             onClick({
//                id: id,
//                name: name,
//                icon: icon,
//                number: number,
//             })
//          }
//          style={{
//             backgroundImage: 'url(' + icon + ')',
//          }}
//          className=" flex h-24 w-24 cursor-pointer items-center justify-center rounded-xl bg-darktext bg-cover bg-center hover:bg-dark-400 hover:shadow-xs md:h-32 md:w-32"
//       >
//          {/* <img src={icon} alt={`${name}-icon`} /> */}
//       </div>
//    )
// }

function Checkout({ method, setTitle, close, cart, cartTotal }) {
   const [response, error, isLoading, setPost] = usePost(
      apiLink + '/api/events/pay',
   )
   const [openConfirm, setOpenConfirm] = useState(false)
   const { openPopUp } = usePopUpContext()
   const [diffCurrency, setDiffCurrency] = useState(false)
   const { closeModal } = useFormModalContext()
   const { changeEvent } = useEventModalContext()
   const [validNumber, setValidNumber] = useState(false)
   const [showDetails, setShowDetails] = useState(false)
   const [number, setNumber] = useState()
   const [payment, setPayment] = useState()

   const handleConfirm = () => {
      setOpenConfirm(!openConfirm)
   }

   const handleShowDetails = () => {
      setShowDetails(!showDetails)
   }
   const {
      register,
      handleSubmit,
      formState: { errors },
   } = useForm()

   useEffect(() => {
      return setTitle({
         title: 'Check Out',
         icon: 'payment',
      })
   }, [])

   const checkInputs = () => {
      return errors.telephone
   }

   const validateNumber = (number) => {
      setNumber(number)
      const regex = /^243\d{9}$/
      regex.test(number) ? setValidNumber(true) : setValidNumber(false)
   }

   const checkDevises = (cart) => {
      const currency = cart[0]?.ticket?.currency
      cart.forEach(({ ticket }) => {
         if (currency !== ticket.currency) {
            setDiffCurrency(true)
         }
      })
   }
   const handlePost_ = (e) => {
      const total = getTotal(cartTotal).split(' ')
      const code =
         'KEPAY' +
         Math.floor(Math.random() * 1000) +
         new Date().getMilliseconds().toString()
      const payement = {
         reference: code,
         type: method,
         phone: e.telephone,
         amount: total[0],
         currency: total[1],
         user_id: getLoggedUser().user.id,
      }
      setPayment(payement)
      handleConfirm()
      // openPopUp({
      //    title: '✅ Votre paiement a été effectué avec succès !',
      //    // content: response,
      //    content: `Merci d'avoir choisi KwetuEvent ! \n\
      //             Vous recevrez sous peu un email de confirmation avec votre billet électronique.`,
      //    default: {
      //       title: '👊🏾',
      //    },
      // })
   }
   const closePayment = () => {
      close && close()
      closeModal && closeModal()
      changeEvent && changeEvent()
   }
   const handlePost = (e) => {
      const data = []
      const total = getTotal(cartTotal).split(' ')
      const code =
         'KEPAY' +
         Math.floor(Math.random() * 1000) +
         new Date().getMilliseconds().toString()
      const payement = {
         reference: code,
         type: method,
         phone: e.telephone,
         amount: total[0],
         currency: total[1],
         user_id: getLoggedUser().user.id,
      }
      setPayment(payement)

      cart.forEach(({ event, ticket, tickets, email }) => {
         // const ref = e?.code1 ?
         tickets.forEach(({ spot, name }) => {
            data.push({
               event_id: event.id,
               reference: code,
               name_holder: name,
               ticket_category_id: ticket.id,
               user_id: getLoggedUser().user.id,
               spot: spot,
            })
         })
      })

      // console.log(payement)
      // console.log(data)
      setPost({ json: { payment: payement, details: data } })
   }

   useEffect(() => {
      cart && checkDevises(cart)
   }, [cart])
   useEffect(() => {
      // console.log(cart)

      if (response?.orderNumber) {
         setPayment({ ...payment, orderNumber: response?.orderNumber })
         handleConfirm()
      }
      //  else {
      //    response &&
      //       openPopUp({
      //          title: '✅ Votre paiement a été effectué avec succès !',
      //          content: response,
      //          // content: `Merci d'avoir choisi KwetuEvent ! \n\
      //          //          Vous recevrez sous peu un email de confirmation avec votre billet électronique.`,
      //          default: {
      //             title: '👊🏾',
      //             handler: () => {
      //                if (response === 'success') {
      //                   close()
      //                   closeModal()
      //                   changeEvent()
      //                }
      //             },
      //          },
      //       })
      // }
      if (error) {
         console.log(error)
         openPopUp({
            title: '⚠️ Paiement Échoué',
            content: error?.message
               ? error?.message
               : 'Impossible de traiter la demande, veuillez réessayer',
            decline: {
               title: 'Réessayer',
            },
         })
      }
   }, [response, error])

   return (
      <>
         {openConfirm && (
            <ConfirmPayment
               payment={payment}
               state={openConfirm}
               close={handleConfirm}
               closePayment={closePayment}
            />
         )}
         <div className="flex w-full flex-col items-center px-4 pb-4 md:w-8/12 md:px-6 md:pb-6">
            <div className="w-full p-2">
               {method === 1 ? <MobileMoney /> : <CreditCard />}
            </div>
            <div className="justify-left flex w-full flex-col flex-wrap items-center gap-4 p-2 pb-4 ">
               <div
                  className={
                     ' bg-bd flex  w-full flex-col items-start gap-2 rounded-md bg-bg p-4 dark:bg-dark'
                  }
               >
                  <div className="flex w-full flex-row items-center justify-between">
                     <span className="px-2 text-center font-serif font-semibold text-dark-400 dark:text-gray-200 sm-max:text-3">
                        Details du Paiement
                     </span>
                     {showDetails ? (
                        <HiChevronUp
                           size={28}
                           onClick={handleShowDetails}
                           className="cursor-pointer text-slate-400 hover:dark:text-slate-200"
                        />
                     ) : (
                        <HiChevronDown
                           onClick={handleShowDetails}
                           size={28}
                           className="cursor-pointer text-slate-400 hover:dark:text-slate-200"
                        />
                     )}
                  </div>
                  {showDetails && (
                     <>
                        {' '}
                        <div className="gap- flex w-full flex-col">
                           {cart.map(({ event, tickets, ticket, id }) => (
                              <BookedTicket
                                 id={id}
                                 event={event}
                                 spots={tickets}
                                 ticket={ticket}
                                 key={id}
                                 minimal
                              />
                           ))}
                        </div>
                        <div className="w-full border-b border-dashed"></div>
                     </>
                  )}
                  <div className="flex w-full flex-row items-center justify-between gap-2 px-2 text-center font-serif font-semibold text-dark-400 dark:text-gray-400">
                     <span>Total à payer :</span>
                     <span className="font-serif font-bold dark:text-gray-200">
                        {getTotal(cartTotal)}
                     </span>
                  </div>
               </div>
               <div className="flex w-full flex-col gap-2">
                  <span className="text-3 font-semibold text-dark-400 dark:text-gray-400 sm:text-4">
                     Téléphone (Votre Numero de Paiement)
                  </span>
                  <TextInput
                     rightIcon={HiPhone}
                     {...register('telephone', { required: true })}
                     color={
                        (errors.telephone || (number && !validNumber)) &&
                        'failure'
                     }
                     onChangeCapture={({ target }) =>
                        validateNumber(target.value)
                     }
                     id="telephone"
                     type="number"
                     placeholder="ex: 243818765345"
                     className="w-full"
                     // value={email}
                     // onChange={(event) => setEmail(event.target.value)}
                     required
                  />
                  {number && !validNumber && (
                     <span className="text-3 font-semibold text-red-700 dark:text-red-400">
                        * numero invalide, le numero doit respecter l'example
                        suivant: 243818765345
                     </span>
                  )}
               </div>

               {diffCurrency && (
                  <TextInput
                     rightIcon={CiBarcode}
                     {...register('code1', { required: true })}
                     color={errors.code && 'failure'}
                     id="code"
                     type="text"
                     placeholder="CDF : Entrez le Code de la Transaction"
                     className="w-full"
                     // value={email}
                     // onChange={(event) => setEmail(event.target.value)}
                     required
                  />
               )}
            </div>
            <div className="flex w-full  flex-col items-center justify-center gap-2 p-2">
               <Button
                  // isProcessing={}
                  disabled={errors.telephone || !validNumber}
                  isProcessing={isLoading}
                  onClick={handleSubmit((e) => !checkInputs() && handlePost(e))}
                  // color="none"
                  className="w-full bg-primary text-slate-200 hover:bg-dark"
               >
                  <span className="text-slate-200"> Payer Maintenant</span>
               </Button>
            </div>
            <div className="w-full border-b border-slate-400 p-2"></div>
            <div
               onClick={handlePost_}
               className="flex w-full flex-row items-center justify-center gap-2 p-2 text-dark-400 dark:text-darktext"
            >
               <HiLockClosed size={18} />
               <span>Paiement sécurisé</span>
            </div>
         </div>
      </>
   )
}

function ConfirmPayment({ state, close, payment, closePayment }) {
   const { openPopUp } = usePopUpContext()
   const [response, error, isLoading, setPost] = usePost(
      apiLink + '/payment/check',
   )
   const { mode } = useThemeMode()
   const { changeForm } = useFormModalContext()
   const [passed, setPassed] = useState(false)
   const [final, setFinal] = useState(false)
   const [transactionState, setTransactionState] = useState('pending')
   const handleCheckTransaction = () => {
      if (!passed && transactionState === 'pending') {
         payment &&
            setPost({
               reference: payment.reference,
               orderNumber: payment.orderNumber,
            })
      }
      // payment &&
      //    setPost({
      //       reference: 'KEPAY786500',
      //       orderNumber: 'snQeSh5PR7fS243991097363',
      //    })
   }

   const handleClose = () => {
      closePayment && closePayment()
      changeForm && changeForm('mes billets')
   }

   /// first one
   useEffect(() => {
      setTransactionState('pending')
      setTimeout(() => {
         transactionState === 'pending' && handleCheckTransaction()
      }, 15 * 1000)
   }, [])

   /// second one
   useEffect(() => {
      setTimeout(() => {
         setFinal(true)
         if (!passed && transactionState === 'pending') {
            handleCheckTransaction()
         }
      }, 30 * 1000)
   }, [])

   // /// third one
   // useEffect(() => {
   //    setTimeout(() => {
   //       console.log('second')
   //       setFinal(true)
   //       transactionState === 'pending' && handleCheckTransaction()
   //    }, 30 * 1000)
   // }, [])

   useEffect(() => {
      if (response) {
         if (response === 'approved') {
            setPassed(true)
            setTransactionState('approved')
         } else {
            if (response === 'rejected') {
               setTransactionState('rejected')
            } else {
               if (response === 'pending') {
                  setTransactionState('rejected')
               }
            }
         }
      }
      if (error) {
         if (final) {
            setTransactionState('rejected')
            openPopUp({
               title: '⚠️ Paiement Échoué',
               content: 'Impossible de traiter la demande, veuillez réessayer',
               decline: {
                  title: 'Réessayer',
               },
            })
         }
      }
   }, [response, error])
   return (
      <CustomPopUp state={state} close={close} popup>
         <div className="flex w-full flex-col items-center gap-2 p-2 text-dark-400 dark:text-darktext">
            <div className="flex flex-col items-center gap-1 md:gap-2">
               {transactionState === 'pending' ? (
                  <span className="text-3 md:text-4">Transaction envoyée.</span>
               ) : transactionState === 'approved' ? (
                  <div className="flex flex-row items-center gap-2">
                     <HiCheckCircle className="text-green-600" />{' '}
                     <span className="text-3 md:text-4">
                        Paiement approuvé.
                     </span>
                  </div>
               ) : (
                  <div className="flex flex-row items-center gap-2">
                     <HiEmojiSad />{' '}
                     <span className="text-3 md:text-4">Paiement échoué.</span>
                  </div>
               )}
               {transactionState === 'pending' ? (
                  <span className="text-center font-semibold">
                     Veuillez valider le push message sur votre telephone.
                  </span>
               ) : transactionState === 'approved' ? (
                  <span className="text-center font-semibold">
                     Votre Paiement a été approuvé avec succès, vos billets sont
                     disponible dans la section Mes Billets
                  </span>
               ) : (
                  <span className="text-center font-semibold">
                     Votre Paiement n'a pas abouti, Veuillez verifier le solde
                     votre compte puis réessayer.
                  </span>
               )}
            </div>
            <div className="flex w-6/12 items-center p-2">
               {transactionState === 'pending' ? (
                  <img
                     src={mode === 'dark' ? confirm_dark : confirm}
                     alt="confirm_svg"
                     className="animate-pulse"
                  />
               ) : transactionState === 'approved' ? (
                  <img
                     src={mode === 'dark' ? approved : approved}
                     alt="confirm_svg"
                     className="animate-pulse"
                  />
               ) : (
                  <img
                     src={mode === 'dark' ? rejected : rejected}
                     alt="confirm_svg"
                     className="animate-pulse"
                  />
               )}
            </div>
            {transactionState === 'pending' || isLoading ? (
               <div className="flex w-full  flex-col items-center justify-center gap-2 p-2">
                  <DottedLoader />
               </div>
            ) : transactionState === 'approved' ? (
               <div className="flex w-full  flex-col items-center justify-center gap-2 p-2">
                  <Button
                     onClick={handleClose}
                     className="w-full bg-primary hover:bg-dark"
                  >
                     Voir les Billets
                  </Button>
               </div>
            ) : (
               <div className="flex w-full  flex-col items-center justify-center gap-2 p-2">
                  <Button
                     onClick={handleCheckTransaction}
                     className="w-full bg-primary hover:bg-dark"
                  >
                     Essayer à nouveau
                  </Button>
                  <Button
                     onClick={() => close && close()}
                     color="none"
                     className="w-full border-none bg-gray-200 text-dark-400 hover:bg-gray-700 hover:text-slate-200 dark:bg-gray-700 dark:text-darktext dark:hover:bg-dark"
                  >
                     Modifier le numero
                  </Button>
               </div>
            )}
            {/* <span>{countd}</span> */}
         </div>
      </CustomPopUp>
   )
}
