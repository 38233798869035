// import './index.css'

import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import NewEventDetails from '../../components/EventDetails/NewEventDetails'
import { DottedLoader } from '../../components/Posts'
import { apiLink, useApiContext } from '../../utilis/ApiContext'
import { useNavContext, useSessionContext } from '../../utilis/Context'
import { useFetch } from '../../utilis/CustomHooks'
import { loadMetaTags } from '../../utilis/functions'
import { SponsoredEVents } from '../Explore'
// import MyContentLoader from '../../components/ContentLoader'
// import Footer from '../../components/Footer'

export default function Events() {
   const id = useParams()?.id
   const { theEvent } = useSessionContext()
   const [event, isLoadingEvent, eventError] = useFetch(
      apiLink + '/api/get_event/' + id,
   )
   const { events } = useApiContext()
   const { refreshEvents } = useApiContext()
   const [localEvent, setEvent] = useState()
   const { handler } = useNavContext()
   // const { changeEvent } = useEventModalContext()

   useEffect(() => {
      if (theEvent && theEvent?.dynamic_id === id) {
         setEvent(theEvent)
      } else {
         if (event && event?.dynamic_id) {
            loadMetaTags(event)
            setEvent(event)
         } else {
            if (events && id) {
               const event_ = events?.find(({ dynamic_id }) => {
                  return dynamic_id === id
               })
               if (event_) {
                  setEvent(event_)
               } else {
                  if (event && event?.dynamic_id) {
                     loadMetaTags(event)
                     setEvent(event)
                  }
               }
            } else {
               if (event && event?.dynamic_id) {
                  loadMetaTags(event)
                  setEvent(event)
               }
            }
         }
      }
   }, [event, isLoadingEvent])
   useEffect(() => {
      if (eventError) {
         console.log(eventError)
      }
   }, [eventError, isLoadingEvent])

   // useEffect(() => {
   //    if (events && id) {
   //       const event = events?.find(({ dynamic_id }) => {
   //          return dynamic_id === id
   //       })
   //       // console.log(event)
   //       event && setEvent(event)
   //    }
   // }, [id])

   useEffect(() => {
      handler('Explorer')
      window.scrollTo(0, 0)
      refreshEvents && refreshEvents()
   }, [])
   return (
      <div className="mb-2 mt-2 flex h-full  w-full max-w-screen-xl flex-col items-center md:mt-5">
         <div className="flex w-full max-w-screen-xl flex-row items-center gap-2 md:gap-4">
            <div className="flex h-full w-full flex-row gap-2 md:gap-4">
               <div className="flex w-full flex-col flex-wrap  items-center gap-4 rounded-xl bg-white p-4 dark:bg-dark-400 lg:w-8/12  lg:gap-6">
                  {localEvent ? (
                     <NewEventDetails event={localEvent} />
                  ) : (
                     <DottedLoader />
                  )}
               </div>
               <SponsoredEVents />
            </div>
         </div>
      </div>
   )
}
