import { eventAssetsLink } from '../ApiContext'

export function randomKey() {
   return (Math.floor(Math.random()) * 230).toString()
}

export async function pasteTextFromClipboard() {
   try {
      if (navigator.clipboard && navigator.clipboard.writeText) {
         // Read the contents of the clipboard.
         const clipboardText = await navigator.clipboard.readText()
         // Display the text in a web page.
         return clipboardText
      } else {
         // console.log('Text past to the not working.')
      }
   } catch (err) {
      console.error('Failed to read clipboard contents: ' + err)
   }
}

export async function copyTextToClipboard(text) {
   try {
      // Write the input text to the clipboard.
      if (navigator.clipboard && navigator.clipboard.writeText) {
         await navigator.clipboard.writeText(text)
         // console.log('Text copied to the clipboard.')
         // Use the writeText() method here.
      } else {
         // Handle the case where the method is not available.
         // console.log('Text copied to the not working.')
      }
   } catch (err) {
      console.error('Failed to write clipboard contents: ' + err)
   }
}

export function disableZoom() {
   let lastTouchEnd = 0
   document.addEventListener(
      'touchend',
      function (event) {
         const now = new Date().getTime()
         if (now - lastTouchEnd <= 300) {
            event.preventDefault()
         }
         lastTouchEnd = now
      },
      false,
   )
}

export function countdown(minutes, setState) {
   let seconds = minutes * 60
   setState()
   const interval = setInterval(() => {
      const minutesLeft = Math.floor(seconds / 60)
      const secondsLeft = seconds % 60
      setState(`${minutesLeft}:${secondsLeft < 10 ? '0' : ''}${secondsLeft}`)
      if (seconds <= 0) {
         clearInterval(interval)
         setState()
      } else {
         seconds--
      }
   }, 1000)
}

export function getCompact(number) {
   const formatter = new Intl.NumberFormat('en', {
      notation: 'compact',
   })

   return formatter.format(+number)
}
export function getDate(date, length = 'short') {
   let dat = new Date(date)
   let options = {
      weekday: length,
      year: 'numeric',
      month: length,
      day: 'numeric',
   }
   return dat.toLocaleDateString('fr-FR', options)
}

export function structureEventsByDate(events) {
   const result = []

   events.forEach((event) => {
      const [year, month, day] = event.date.split('-')

      let yearObj = result.find((y) => y.year === year)

      if (!yearObj) {
         yearObj = { year, months: [] }
         result.push(yearObj)
      }

      let monthObj = yearObj.months.find((m) => m.num === month)

      if (!monthObj) {
         const monthName = new Date(year, month - 1).toLocaleString('fr-FR', {
            month: 'long',
         })
         monthObj = { name: monthName, num: month, dates: [] }
         yearObj.months.push(monthObj)
      }

      monthObj.dates.push(parseInt(day))
      monthObj.dates.sort((a, b) => a - b)
   })

   return result
}

export function loadMetaTags(event) {
   document.title = `${event.title} - KwetuEvent`
   document
      .querySelector('meta[name="description"]')
      .setAttribute(
         'content',
         `Rejoignez-nous pour ${event.title} le ${event.date}. Ne manquez pas cet événement incroyable !`,
      )
   document
      .querySelector('meta[property="og:title"]')
      .setAttribute('content', `${event.title} - KwetuEvent`)
   document
      .querySelector('meta[property="og:description"]')
      .setAttribute(
         'content',
         `Rejoignez-nous pour ${event.title} le ${event.date}. Ne manquez pas cet événement incroyable !`,
      )
   document
      .querySelector('meta[property="og:image"]')
      .setAttribute('content', eventAssetsLink + event.banner)
   document
      .querySelector('meta[name="twitter:title"]')
      .setAttribute('content', `${event.title} - KwetuEvent`)
   document
      .querySelector('meta[name="twitter:description"]')
      .setAttribute(
         'content',
         `Rejoignez-nous pour ${event.title} le ${event.date}. Ne manquez pas cet événement incroyable !`,
      )
   document
      .querySelector('meta[name="twitter:image"]')
      .setAttribute('content', eventAssetsLink + event.banner)
   // console.log(event)
}
export function resetMetaTags() {
   document.title = `KwetuEvent - Vente en ligne des billets pour événements`
   document
      .querySelector('meta[name="description"]')
      .setAttribute(
         'content',
         `Achetez vos billets pour les meilleurs événements en ligne avec KwetuEvent. Simple, rapide et sécurisé.`,
      )
   document
      .querySelector('meta[property="og:title"]')
      .setAttribute(
         'content',
         `KwetuEvent - Vente en ligne des billets pour événements`,
      )
   document
      .querySelector('meta[property="og:description"]')
      .setAttribute(
         'content',
         `Achetez vos billets pour les meilleurs événements en ligne avec KwetuEvent. Simple, rapide et sécurisé.`,
      )
   document
      .querySelector('meta[property="og:image"]')
      .setAttribute('content', '/images/logo.png')
   document
      .querySelector('meta[name="twitter:title"]')
      .setAttribute(
         'content',
         `KwetuEvent - Vente en ligne des billets pour événements`,
      )
   document
      .querySelector('meta[name="twitter:description"]')
      .setAttribute(
         'content',
         `Achetez vos billets pour les meilleurs événements en ligne avec KwetuEvent. Simple, rapide et sécurisé.`,
      )
   document
      .querySelector('meta[name="twitter:image"]')
      .setAttribute('content', '/images/logo.png')
   // console.log(event)
}
