import { Link } from 'react-router-dom'

import { useEffect, useState } from 'react'
import { FaCompass } from 'react-icons/fa'
import {
   HiChatAlt,
   HiMenu,
   HiOutlineBell,
   HiOutlineTicket,
} from 'react-icons/hi'
import { RiHomeFill } from 'react-icons/ri'
import {
   useCartContext,
   useFormModalContext,
   useSessionContext,
} from '../../utilis/Context'
import { LogoV2 } from '../Brand/Logo'
import Nav from '../Nav'
import NavIcon from '../NavIcon'

export default function NavBar() {
   // const handleShowMenu = (title) => {
   //    SetShowMenu(!showMenu)
   //    // console.log(active)
   // }
   const { changeForm } = useFormModalContext()
   const { loggedUser } = useSessionContext()
   const { cart, openModal } = useCartContext()

   return (
      <div className="z-20 h-12 w-full items-center  justify-center md:h-14 md:max-w-screen-xl">
         <div className="fixed left-0 top-0 hidden h-20  w-full items-center justify-center border-b bg-white dark:border-gray-700 dark:bg-dark-400 lg:flex">
            <div className="flex w-full max-w-screen-xl items-center justify-between">
               <div className="flex flex-row items-center gap-10">
                  <div className="flex h-full  items-center justify-start  px-10">
                     <Link to="/">
                        <LogoV2 size="4" />
                     </Link>
                     {/* <Link
                        to="/"
                        className="text-5 font-semibold hover:text-primary dark:text-white"
                     >
                        Kwetu<span className="text-primary">Event</span>
                     </Link> */}
                  </div>
                  <div className="flex h-full flex-row items-center justify-start gap-10  rounded-br-2xl">
                     <Nav title={'Accueil'} HiCon={RiHomeFill} link={'/'} />
                     <Nav
                        title={'Explorer'}
                        link={'/explore'}
                        HiCon={FaCompass}
                     />
                     <Nav
                        title={'Mes Tickets'}
                        HiCon={HiOutlineTicket}
                        rotate
                        onClick={() => changeForm('mes billets')}
                     />
                     <Nav
                        title={'Contacts'}
                        HiCon={HiChatAlt}
                        onClick={() => changeForm('contactez nous')}
                     />
                  </div>
               </div>

               <div className="flex h-full items-center justify-center gap-2 px-10">
                  {cart.length > 0 && (
                     <div className="cursor-pointer" onClick={openModal}>
                        <span
                           onClick={openModal}
                           className="absolute top-2 h-10 w-10 cursor-pointer pb-6 pr-6"
                        >
                           <span className="flex h-5 w-5 cursor-pointer items-center justify-center rounded-full bg-red-500 text-2 text-white">
                              {cart.length}
                           </span>
                        </span>
                        <Link
                           // to="/"
                           style={{
                              before: {
                                 content: '10',
                              },
                           }}
                           className="material-icons-round rounded-full text-8 text-gray-600 hover:text-primary dark:text-slate-400 dark:hover:text-white"
                        >
                           shopping_cart
                        </Link>
                     </div>
                  )}

                  {!loggedUser ? (
                     <Link
                        onClick={() => changeForm('connexion')}
                        className="bg-gradient rounded-full bg-gradient-to-r from-indigo-600 to-blue-700 px-2 py-1 font-semibold text-white hover:text-darktext-white  dark:text-slate-200"
                     >
                        Se Connecter
                     </Link>
                  ) : (
                     <HiOutlineBell
                        onClick={() => changeForm('notifications')}
                        className="material-icons-round cursor-pointer rounded-full text-8 text-gray-600 dark:text-slate-400 dark:hover:text-white"
                     />
                  )}
                  <HiMenu
                     onClick={() => changeForm('menu')}
                     className="material-icons-round cursor-pointer rounded-full text-8 text-gray-600 dark:text-slate-400 dark:hover:text-white"
                  />
               </div>
            </div>
         </div>
         <div className="flex w-full max-w-screen-xl items-center justify-between lg:hidden">
            <div className="sticky top-0 flex  w-full flex-row  items-center justify-between px-4 py-2">
               <div className="flex flex-col">
                  <span className="font-bold text-gray-600 dark:text-slate-200 sm:text-5 md:text-6">
                     Salut{loggedUser ? ', ' + loggedUser?.username : ''} 👋🏾
                  </span>
                  <span className="text-gray-500 dark:text-gray-400 sm:font-semibold">
                     Bienvenue sur KwetuEvent
                  </span>
               </div>
               <div className="flex items-center justify-center gap-2">
                  {cart.length > 0 && (
                     <div className="cursor-pointer" onClick={openModal}>
                        <span
                           onClick={openModal}
                           className="absolute top-2 h-10 w-10 cursor-pointer pb-6 pr-6"
                        >
                           <span className="flex h-5 w-5 cursor-pointer items-center justify-center rounded-full bg-red-500 text-2 text-white">
                              {cart.length}
                           </span>
                        </span>
                        <Link
                           // to="/"
                           style={{
                              before: {
                                 content: '10',
                              },
                           }}
                           className="material-icons-round rounded-full text-8 hover:text-primary dark:text-slate-400 dark:hover:text-white"
                        >
                           shopping_cart
                        </Link>
                     </div>
                  )}
                  {loggedUser ? (
                     <>
                        <HiOutlineBell
                           onClick={() => changeForm('notifications')}
                           className="material-icons-round cursor-pointer  rounded-full text-8 text-gray-600 dark:text-slate-400 dark:hover:text-white"
                        />

                        <UserAvatar onClick={() => changeForm('mon compte')} />
                     </>
                  ) : (
                     <Link
                        onClick={() => changeForm('connexion')}
                        className="bg-gradient rounded-full bg-gradient-to-r from-indigo-600 to-blue-700 px-2 py-1 font-semibold text-white hover:text-darktext-white  dark:text-slate-200"
                     >
                        Se Connecter
                     </Link>
                  )}
               </div>
            </div>
         </div>
         <div className="fixed bottom-0 left-0 flex w-screen max-w-screen-xl  flex-col items-center justify-center bg-white shadow-xs shadow-gray-300 dark:bg-dark-400 dark:shadow-zinc-900 lg:hidden">
            <div className="items-centers flex h-full w-full flex-row justify-around border-zinc-300  pb-4 dark:border-gray-500">
               <NavIcon
                  link="/"
                  name="Accueil"
                  HiCon={RiHomeFill}
                  title="Accueil"
               />
               <NavIcon
                  HiCon={FaCompass}
                  link="/explore"
                  name="Explorer"
                  title="Explorer"
               />
               {/* <Cam /> */}
               <NavIcon
                  onClick={() => changeForm('mes billets')}
                  // link="/"
                  rotate
                  HiCon={HiOutlineTicket}
                  name="Mes Tickets"
                  title="My Tickets"
               />
               {/* <NavIcon link="/library" name="receipt" title="Livres" /> */}
               <NavIcon
                  onClick={() => changeForm('menu')}
                  // link="/"
                  HiCon={HiMenu}
                  name="Profile"
                  title="Profile"
               />
            </div>
         </div>
      </div>
   )
}

export function UserAvatar({
   className = 'h-9 w-9',
   onClick,
   text = 'text-4',
}) {
   const { loggedUser } = useSessionContext()
   const [username, setUsername] = useState('AB')

   const handleName = (name) => {
      const arr = name.split(' ')
      setUsername(
         arr[0].charAt(0) + (arr[1]?.charAt(0) ? arr[1]?.charAt(0) : ''),
      )
   }
   useEffect(() => {
      loggedUser && handleName(loggedUser.first_name)
   }, [loggedUser])
   return (
      <div
         onClick={() => onClick && onClick()}
         className={
            className +
            ` flex ${text} cursor-pointer items-center justify-center rounded-full bg-gray-600 text-slate-200 dark:bg-slate-400 dark:text-dark dark:hover:text-gray-200 `
         }
      >
         <span className="font-bold">{username}</span>
      </div>
   )
}

// function Cam() {
//    return (
//       <ScanModalContext.Consumer>
//          {({ openModal }) => (
//             <div
//                onClick={() => {
//                   openModal()
//                }}
//                className="cursor-pointer rounded-full p-4 bg-dark-400 dark:bg-white mb-10"
//             >
//                <Link className="flex flex-col justify-center items-center dark:text-dark-400 text-dark">
//                   <span className="material-icons-round text-8">camera</span>
//                </Link>
//             </div>
//          )}
//       </ScanModalContext.Consumer>
//    )
// }
