import axios from 'axios'
import CryptoJS from 'crypto-js'
import { createContext, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { apiLink } from '../ApiContext'
import { usePost } from '../CustomHooks'
import { loadMetaTags } from '../functions'

export const session = window.localStorage

export const NavContext = createContext()

export const NavProvider = ({ children }) => {
   const [activeNav, setActiveNav] = useState('Events')

   const handler = (title) => {
      setActiveNav(title)
   }

   return (
      <NavContext.Provider value={{ activeNav, handler }}>
         {children}
      </NavContext.Provider>
   )
}

export const useNavContext = () => {
   const context = useContext(NavContext)

   if (typeof context === 'undefined') {
      throw new Error('useNavContext must be used inside NavContextProvider')
   }

   return context
}

//crypting
const encryptUserDetails = (data, secretKey) => {
   const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      secretKey,
   ).toString()
   return encryptedData
}

// Function to decrypt user details
const decryptUserDetails = (encryptedData, secretKey) => {
   const decryptedData = CryptoJS.AES.decrypt(
      encryptedData,
      secretKey,
   ).toString(CryptoJS.enc.Utf8)
   return JSON.parse(decryptedData)
}

const secretKey = 'Kabanga66'

export const SessionContext = createContext()

export const getLoggedUser = () => {
   const storedUser = session.getItem('_kejtc_h')
   const storedToken = session.getItem('34udsgh_s')
   const sessionTime = session.getItem('eps$h_k')
   if (sessionTime) {
      const expiry = decryptUserDetails(sessionTime, secretKey)
      const time = new Date(expiry).getTime()
      const now = new Date().getTime()
      if (storedUser && time > now) {
         const user = decryptUserDetails(storedUser, secretKey)
         const token = JSON?.parse(storedToken)

         if (user && token) {
            return {
               user: user,
               token: token,
               expiry: expiry,
            }
         } else {
            return false
         }
      } else {
         return false
      }
   } else {
      return false
   }
}

//////

export const SessionProvider = ({ children }) => {
   const location = useLocation()
   const [theEvent, setTheEvent] = useState()
   const [loggedUser, setLoggedUser] = useState()
   const [loggedUserToken, setLoggedUserToken] = useState()
   const [sessionExpiry, setSessionExpiry] = useState()
   // const [effect, setEffect] = useState()

   // const navigate = useNavigate()

   const startSession = (user, token = null, expiry = null) => {
      user && session.setItem('_kejtc_h', encryptUserDetails(user, secretKey))
      token && session.setItem('34udsgh_s', JSON.stringify(token))
      expiry &&
         session.setItem('eps$h_k', encryptUserDetails(expiry, secretKey))
      user && StoreSession(user)
      token && StoreToken(token)
      expiry && StoreExpiry(expiry)
   }

   const StoreSession = (user) => {
      user && user?.email && setLoggedUser(user)
      // : window.location.assign('/login')
   }
   const StoreToken = (token) => {
      token && setLoggedUserToken(token)
      // : window.location.assign('/login')
   }
   const StoreExpiry = (token) => {
      token && setSessionExpiry(token)
      // : window.location.assign('/login')
   }

   const destroySession = () => {
      session.removeItem('_kejtc_h')
      session.removeItem('34udsgh_s')
      session.removeItem('eps$h_k')
      setLoggedUser()
      setLoggedUserToken()
      setSessionExpiry()
      // window.location.assign('/')
   }

   const checkSession = () => {
      if (sessionExpiry) {
         const time = new Date(sessionExpiry).getTime()
         const now = new Date().getTime()
         if (!(time > now)) {
            destroySession()
         }
      } else {
         destroySession()
      }
   }

   const fetch_event = async (id) => {
      try {
         const response = await axios.get(apiLink + '/api/get_event/' + id, {
            'Content-Type': 'application/json',
         })
         return response.data
      } catch (err) {
         console.log(err)
      }
   }
   useEffect(() => {
      const pathParts = location.pathname.split('/')
      if (pathParts[1] === 'events' && pathParts[2]) {
         const eventId = pathParts[2]
         // Perform some action with eventId before other things
         // console.log('Event ID:', eventId)
         // You can fetch specific event details or perform other actions here
         fetch_event(eventId).then((event) => {
            if (event && event?.dynamic_id) {
               loadMetaTags(event)
               setTheEvent(event)
            }
         })
      }
   }, [location])

   useEffect(() => {
      const interval = setInterval(
         () => {
            checkSession()
         },
         1000 * 6 * 5,
      )
      return clearInterval(interval)
   }, [])

   useEffect(() => {
      if (!loggedUser && !loggedUserToken && !sessionExpiry) {
         const savedSession = getLoggedUser()
         if (savedSession) {
            savedSession?.user && StoreSession(savedSession.user)
            savedSession?.token && StoreToken(savedSession.token)
            savedSession?.expiry && StoreExpiry(savedSession?.expiry)
         }
      }
   }, [loggedUser, loggedUserToken, sessionExpiry])

   return (
      <SessionContext.Provider
         value={{
            loggedUser,
            loggedUserToken,
            startSession,
            destroySession,
            sessionExpiry,
            theEvent,
            setTheEvent,
         }}
      >
         {children}
      </SessionContext.Provider>
   )
}

export const useSessionContext = () => {
   const context = useContext(SessionContext)

   if (typeof context === 'undefined') {
      throw new Error(
         'useSessionContext must be used inside SessionContextProvider',
      )
   }

   return context
}

/////

export const EventModalContext = createContext()

export const EventModalProvider = ({ children }) => {
   const [modalState, setModalState] = useState(false)
   const [response, error, isLoading, setPost] = usePost(
      apiLink + '/api/view_event',
   )
   const [event, setEvent] = useState(null)

   const closeModal = () => {
      setModalState(false)
   }
   const openModal = () => {
      setModalState(true)
   }
   const changeEvent = (ev) => {
      ev && setEvent(ev)
      // console.log(ev)
      ev && setModalState(true)
      !ev && setModalState(false)
   }

   useEffect(() => {
      event && setPost({ event_id: event.id })
   }, [event])

   return (
      <EventModalContext.Provider
         value={{
            modalState,
            closeModal,
            openModal,
            event,
            changeEvent,
            response,
            error,
            isLoading,
         }}
      >
         {children}
      </EventModalContext.Provider>
   )
}

export const useEventModalContext = () => {
   const context = useContext(EventModalContext)

   if (typeof context === 'undefined') {
      throw new Error('useContext must be used inside EventModalProvider')
   }

   return context
}

/////////////

export const CategoryModalContext = createContext()

export const CategoryModalProvider = ({ children }) => {
   const [modalState, setModalState] = useState(false)
   const [category, setCategory] = useState(null)

   const closeModal = () => {
      setModalState(false)
      setCategory('')
   }
   const openModal = () => {
      setModalState(true)
   }
   const changeCategory = (cat) => {
      setCategory(cat)
   }

   useEffect(() => {
      category && setModalState(true)
   }, [category])

   return (
      <CategoryModalContext.Provider
         value={{ modalState, closeModal, openModal, category, changeCategory }}
      >
         {children}
      </CategoryModalContext.Provider>
   )
}

export const useCategoryModalContext = () => {
   const context = useContext(CategoryModalContext)

   if (typeof context === 'undefined') {
      throw new Error('useContext must be used inside CategoryModalProvider')
   }

   return context
}
//////////

export const ExploreModalContext = createContext()

export const ExploreModalProvider = ({ children }) => {
   const [modalState, setModalState] = useState(false)
   const [dateModalState, setDateModalState] = useState(false)
   const [category, setCategory] = useState(null)
   const [date, setDate] = useState(null)

   const closeModal = () => {
      setModalState(false)
      setDate(null)
   }

   const closeDateModal = () => {
      setDateModalState(false)
      setCategory(null)
   }
   const openModal = () => {
      setModalState(true)
   }
   const changeCategory = (cat) => {
      setCategory(cat)
   }

   const changeDate = (dat) => {
      // setSelectedDate(date);
      const da = new Date(dat)
      setDate(da.toLocaleDateString())
   }

   useEffect(() => {
      date && setDateModalState(true)
   }, [date])

   return (
      <ExploreModalContext.Provider
         value={{
            modalState,
            dateModalState,
            closeModal,
            closeDateModal,
            openModal,
            category,
            changeCategory,
            date,
            changeDate,
         }}
      >
         {children}
      </ExploreModalContext.Provider>
   )
}

export const useExpoloreModalContext = () => {
   const context = useContext(ExploreModalContext)

   if (typeof context === 'undefined') {
      throw new Error(
         'useExpoloreModalContext must be used inside ExploreModalContext',
      )
   }

   return context
}

//////////
export const ScanModalContext = createContext()

export const ScanModalProvider = ({ children }) => {
   const [modalState, setModalState] = useState(false)
   // const [dateModalState, setDateModalState] = useState(false)
   // const [category, setCategory] = useState(null)
   // const [date, setDate] = useState(null)

   const closeModal = () => {
      setModalState(false)
      // setDate(null)
   }

   // const closeDateModal = () => {
   //    setDateModalState(false)
   //    setCategory(null)
   // }
   const openModal = () => {
      setModalState(true)
   }
   // const changeCategory = (cat) => {
   //    setCategory(cat)
   // }

   // const changeDate = (dat) => {
   //    // setSelectedDate(date);
   //    const da = new Date(dat)
   //    setDate(da.toLocaleDateString())
   // }

   // useEffect(() => {
   //    date && setDateModalState(true)
   // }, [date])

   return (
      <ScanModalContext.Provider
         value={{
            modalState,
            // dateModalState,
            closeModal,
            // closeDateModal,
            openModal,
            // category,
            // changeCategory,
            // date,
            // changeDate,
         }}
      >
         {children}
      </ScanModalContext.Provider>
   )
}

export const FormModalContext = createContext()

export const FormModalProvider = ({ children }) => {
   // const [modalState, setModalState] = useState(false)
   const [loginModalState, setLoginModalState] = useState(false)
   const [view, setView] = useState(null)

   const closeModal = () => {
      setLoginModalState(false)
   }

   const changeForm = (vw) => {
      setView(vw)
      !loginModalState && setLoginModalState(true)
   }

   return (
      <FormModalContext.Provider
         value={{ loginModalState, closeModal, view, changeForm }}
      >
         {children}
      </FormModalContext.Provider>
   )
}

export const useFormModalContext = () => {
   const context = useContext(FormModalContext)

   if (typeof context === 'undefined') {
      throw new Error(
         'useFormModalContext must be used inside FormModalProvider',
      )
   }

   return context
}

////////
export const PopUpContext = createContext()

export const PopUpProvider = ({ children }) => {
   const [popUpState, setPopUpState] = useState()

   const closePopUp = () => {
      setPopUpState(null)
   }
   const openPopUp = (popup) => {
      setPopUpState(popup)
   }

   return (
      <PopUpContext.Provider value={{ popUpState, closePopUp, openPopUp }}>
         {children}
      </PopUpContext.Provider>
   )
}
export const usePopUpContext = () => {
   const context = useContext(PopUpContext)

   if (typeof context === 'undefined') {
      throw new Error('usePopUpContext must be used inside PopUpProvider')
   }

   return context
}

////////
export const CartContext = createContext()

export const CartProvider = ({ children }) => {
   const [modalState, setModalState] = useState(false)
   const [cart, setCart] = useState([])
   const [cartTotal, setCartTotal] = useState([])

   const closeModal = () => {
      setModalState(false)
   }
   const openModal = () => {
      setModalState(true)
   }
   const addToCart = (data) => {
      // console.log(data)
      setCart([...cart, data])
      openModal()
   }
   const deleteFromCart = (data) => {
      const holder = cart.filter(({ id }) => {
         return id !== data
      })
      holder.length > 0 ? setCart(holder) : clearCart()
   }
   const clearCart = (data) => {
      // console.log(cart)
      setCart([])
      setCartTotal([])
      closeModal()
   }
   const getCartTotal = () => {
      // let total = 0

      // //filter by currency
      // let jokerCurr = cart[0].ticket.currency
      // let dat = []
      // cart.forEach(({ ticket }) => {
      // dat.push({
      //    currency: jokerCurr,
      //    tickets: cart.filter(({ ticket }) => {
      //       return ticket.currency === jokerCurr
      //    }),
      // })
      // dat.push({
      //    currency: jokerCurr,
      //    tickets: cart.filter(({ ticket }) => {
      //       return ticket.currency !== jokerCurr
      //    }),
      // })

      const data = cart.reduce((prev, current) => {
         const key = current.ticket.currency
         if (!prev[key]) {
            prev[key] = []
         }
         prev[key].push(current)
         return prev
      }, {})
      const newCart = Object.values(data)
      if (newCart.length > 0) {
         let cartTot = []
         newCart.forEach((category) => {
            const currency = category[0].ticket.currency
            let total = 0
            category.forEach(({ ticket, tickets }) => {
               total += ticket.price * tickets.length
            })
            cartTot.push({
               currency: currency,
               total: total,
            })
         })
         setCartTotal(cartTot)
      } else {
         setCartTotal([])
      }
   }

   useEffect(() => {
      cart && cart.length > 0 ? getCartTotal() : setCartTotal([])
   }, [cart])

   return (
      <CartContext.Provider
         value={{
            modalState,
            closeModal,
            openModal,
            cart,
            addToCart,
            clearCart,
            cartTotal,
            deleteFromCart,
         }}
      >
         {children}
      </CartContext.Provider>
   )
}

export const useCartContext = () => {
   const context = useContext(CartContext)

   if (typeof context === 'undefined') {
      throw new Error('useCartContext must be used inside CartProvider')
   }

   return context
}
