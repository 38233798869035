import { HiCreditCard, HiOutlinePhone } from 'react-icons/hi'
import afrimoney from '../../assets/icons/afrimoney.png'
import airtel from '../../assets/icons/airtel.png'
import mastercard from '../../assets/icons/mastercard.png'
import mpesa from '../../assets/icons/mpesa.png'
import orange from '../../assets/icons/orange.jpeg'
import visa from '../../assets/icons/visa.png'

export default function PaymentMethod({ handleMethod }) {
   return (
      <div className="flex w-full flex-col items-center justify-center gap-4 sm:flex-row md:gap-8 md:p-5">
         <MobileMoney handler={handleMethod} />
         <CreditCard handler={handleMethod} disabled />
      </div>
   )
}
export function PaymentMethods() {
   return (
      <div className="flex w-full flex-row items-center gap-4 md:gap-8">
         <div className="flex flex-row flex-wrap items-center gap-2">
            <Icon img={airtel} />
            <Icon img={mpesa} />
            <Icon img={orange} />
            <Icon img={afrimoney} />
            <Icon img={visa} disabled />
            <Icon img={mastercard} disabled />
         </div>
      </div>
   )
}

export function MobileMoney({ handler, disabled }) {
   return (
      <div
         onClick={() => !disabled && handler && handler(1)}
         className={
            (disabled ? 'cursor-not-allowed' : 'cursor-pointer') +
            ' bg-bd flex flex-col items-start gap-2 rounded-md bg-bg p-4 dark:bg-dark'
         }
      >
         <div className="flex w-full flex-row items-center justify-between">
            <span className="font-semibold text-dark-400 dark:text-darktext">
               Mobile Money
            </span>
            <HiOutlinePhone
               size={24}
               className="tet-green-500 text-slate-600"
            />
         </div>
         <div className="flex flex-row flex-wrap items-center gap-2">
            <Icon img={airtel} disabled={disabled} />
            <Icon img={mpesa} disabled={disabled} />
            <Icon img={orange} disabled={disabled} />
            <Icon img={afrimoney} disabled={disabled} />
         </div>
      </div>
   )
}
export function CreditCard({ handler, disabled }) {
   return (
      <div
         onClick={() => !disabled && handler && handler(2)}
         className={
            (disabled ? 'cursor-not-allowed' : 'cursor-pointer') +
            ' bg-bd flex  flex-col items-start gap-2 rounded-md bg-bg p-4 dark:bg-dark'
         }
      >
         <div className="flex w-full flex-row items-center justify-between gap-4">
            <span className="font-semibold text-dark-400 dark:text-darktext">
               Carte de Crédit
            </span>
            <HiCreditCard size={24} className="tet-green-500 text-slate-600" />
         </div>
         <div className="flex items-center gap-2">
            <Icon img={visa} disabled={disabled} />
            <Icon img={mastercard} disabled={disabled} />
         </div>
      </div>
   )
}

function Icon({ img, disabled }) {
   return (
      <div
         style={{
            backgroundImage: 'url(' + img + ')',
         }}
         className={
            (disabled
               ? 'cursor-not-allowed grayscale filter '
               : 'cursor-pointer') +
            ' flex h-8 w-8 items-center justify-center rounded-md bg-darktext bg-cover bg-center md:h-12 md:w-12'
         }
      >
         {/* <img src={icon} alt={`${name}-icon`} /> */}
      </div>
   )
}
