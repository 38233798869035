import { Button } from 'flowbite-react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FaShoppingCart } from 'react-icons/fa'
import {
   HiInformationCircle,
   HiMinus,
   HiOutlineTicket,
   HiPlus,
} from 'react-icons/hi'
import { apiLink } from '../../utilis/ApiContext'
import {
   getLoggedUser,
   useCartContext,
   useEventModalContext,
   useFormModalContext,
   usePopUpContext,
   useSessionContext,
} from '../../utilis/Context'
import { usePost } from '../../utilis/CustomHooks'
import CustomPopUp from '../CustomPopUp/CustomPopUp'
import { Email, Input } from '../Form'

export default function Booking({ event, show, onClose }) {
   // const [categories, setCategories] = useState([])

   // useEffect(() => {
   //    if (event?.ticket_categories?.length > 0) {
   //       setCategories(event.ticket_categories)
   //       if (event?.ticket_categories?.length = 1) {

   //       }

   //    }
   // }, [event])
   // const event = null
   return (
      <div className="flex  w-full flex-col p-4">
         <span className="w-full px-2 text-center font-semibold sm-max:text-3">
            Sectionnez une Catégorie pour commencer.
         </span>
         <div className="flex flex-col justify-center gap-2 p-2">
            {event &&
               event.ticket_categories.map((ticket) => (
                  <TicketCategory
                     // key={event.id + id}
                     // event={event}
                     event={event}
                     ticket={ticket}
                     ticketId={ticket.id}
                     type={ticket.type}
                     price={ticket.price}
                     key={ticket.type + ticket.price}
                     currency={ticket.currency}
                     spots={ticket.spots}
                     available={ticket.available}
                  />
               ))}
         </div>
      </div>
   )
}
export function NewBooking({ event }) {
   return (
      <div className="flex  w-full flex-col">
         <span className="w-full px-2 text-left font-semibold sm-max:text-3">
            Tickets Disponibles
         </span>
         <div className="flex flex-col justify-center gap-2 py-2">
            {event &&
               event.ticket_categories.map((ticket) => (
                  <TicketCategory
                     // key={event.id + id}
                     // event={event}
                     event={event}
                     ticket={ticket}
                     ticketId={ticket.id}
                     type={ticket.type}
                     price={ticket.price}
                     key={ticket.type + ticket.price}
                     currency={ticket.currency}
                     spots={ticket.spots}
                     available={ticket.available}
                  />
               ))}
         </div>
      </div>
   )
}

function TicketCategory({
   ticket,
   event,
   type,
   price,
   currency,
   spots,
   available,
}) {
   const [ticketPopUp, setTicketPopUp] = useState(false)
   const [showNameHolder, setShowNameHolder] = useState(false)
   const [ticketHolder, setTicketHolder] = useState(false)
   const [tickets, setTickets] = useState(1)
   const { changeForm } = useFormModalContext()
   const { loggedUser } = useSessionContext()
   const { openPopUp } = usePopUpContext()
   const [bookedTickets, setBookedTickets] = useState([
      {
         spot: spots - available + 1,
         index: 1,
         name: loggedUser ? loggedUser?.first_name : '',
         // setName : (n) => {return this.name = n}
      },
   ])
   // const []
   const openPopLocal = () => {
      setTicketPopUp(true)
   }
   const openTicketHolder = () => {
      setShowNameHolder(true)
      setTicketHolder(true)
   }
   const closePop = () => {
      setTicketPopUp(false)
   }
   const closeTicketHolder = () => {
      setTicketHolder(false)
   }

   const removeSpots = () => {
      tickets > 1 && setTickets(Number(tickets) - 1)
   }
   const addSpots = () => {
      if (tickets < available) {
         if (+ticket?.is_free !== 1) {
            tickets < 5 && setTickets(Number(tickets) + 1)
         }
      }
      // !+ticket.is_free === 1 && tickets < 5 && setTickets(Number(tickets) + 1)
   }
   const handleChange = (e) => {
      Number(e.target.value) > 0 && Number(e.target.value) <= available
         ? setTickets(e.target.value)
         : setTickets(tickets)
   }

   const addTicket = (sum) => {
      // console.log(sum)
      for (let index = 0; index < sum; index++) {
         const lastTiket = bookedTickets[bookedTickets.length - 1]
         // console.log(lastTiket)
         setBookedTickets([
            ...bookedTickets,
            {
               spot: lastTiket.spot + 1,
               index: bookedTickets.length + 1,
               name: '',
               // setName : (n) => {return this.name = n}
            },
         ])
      }
   }
   const removeTicket = (sum) => {
      const holder = bookedTickets
      for (let index = 0; index < sum; index++) {
         holder.pop()
      }
      setBookedTickets(holder)
   }

   useEffect(() => {
      tickets <= 1 && setTickets(1)
      // console.log(1)
      if (bookedTickets.length < tickets) {
         addTicket(tickets)
      } else {
         if (bookedTickets.length > tickets && tickets > 0) {
            removeTicket(bookedTickets.length - tickets)
         }
      }
      // console.log(bookedTickets.length <= tickets)
   })

   return (
      <>
         <div
            onClick={() =>
               +ticket?.available > 0 &&
               +ticket?.is_active === 1 &&
               openPopLocal()
            }
            className={
               +ticket?.is_active === 0
                  ? 'flex w-full cursor-not-allowed flex-row items-center justify-between gap-2 rounded-xl bg-bg p-2 px-4  opacity-70 dark:bg-dark'
                  : 'flex w-full cursor-pointer flex-row items-center justify-between gap-2 rounded-xl bg-bg p-2 px-4 dark:bg-dark '
            }
         >
            <div className="flex flex-row items-center gap-4">
               <HiOutlineTicket className=" -rotate-45 text-8 text-blue-500" />
               <div className="flex flex-col">
                  <span className="test-slate-200 font-light sm-max:text-3">
                     {type}
                  </span>
                  {+ticket?.is_free === 1 ? (
                     <div className="flex flex-col">
                        <span className="font-bold text-gray-700 dark:text-slate-200 sm:text-4">
                           ENTREE LIBRE
                        </span>
                        {+ticket?.is_active === 0 && (
                           <span className="font-serif text-3 font-bold text-red-600 opacity-60 dark:text-red-400 sm:text-4">
                              billetterie indisponible pour l'instant
                           </span>
                        )}
                     </div>
                  ) : (
                     <div className="flex flex-col">
                        <span className="font-bold text-gray-700 dark:text-slate-200 sm:text-4">
                           {price + ' ' + currency}
                        </span>
                        {+ticket?.is_active === 0 && (
                           <span className="font-serif text-3 font-bold text-red-600 opacity-60 dark:text-red-400 sm:text-4">
                              billetterie indisponible pour l'instant
                           </span>
                        )}
                     </div>
                  )}
               </div>
            </div>
            {+ticket?.available < 1 ? (
               <span className="rounded-4 bg-red-700 p-1 px-2 text-3 font-semibold text-white dark:bg-red-400">
                  Sold Out
               </span>
            ) : (
               <HiInformationCircle className="material-icons-round justify-end text-gray-700 dark:text-slate-400" />
            )}
         </div>
         <CustomPopUp
            title={
               showNameHolder
                  ? 'Propriétaires de Tickets'
                  : 'Ticket ' +
                    type +
                    ' ' +
                    (+ticket.is_free === 1 ? '' : price + ' ' + currency)
            }
            state={ticketPopUp}
            close={showNameHolder ? () => setShowNameHolder(false) : closePop}
         >
            {!showNameHolder ? (
               <div className="flex w-full flex-col gap-2 p-2">
                  {/* <div className="mb-2 flex flex-row items-center justify-around px-2 pb-2">
                     <HiOutlineTicket className=" -rotate-45 material-icons-round dark:opacity- rounded-full bg-opacity-60 bg-gradient-to-r from-indigo-400 to-cyan-700 p-2 text-10 text-slate-200" />
                     <span className="font-semibold text-dark-400 dark:text-darktext">
                        Tickets Disponible :
                     </span>
                     <span className="font-semibold text-dark-400 dark:text-darktext">
                        {available > 0 ? 'DISPONIBLE' : 'SOLD OUT'}
                     </span>
                  </div> */}
                  <div className="flex flex-row items-center justify-around gap-2 p-2">
                     <HiOutlineTicket className=" dark:opacity-  -rotate-45 rounded-full bg-opacity-60 bg-gradient-to-r from-cyan-600 to-blue-400 p-2 text-10 text-slate-200" />
                     <span className="font-semibold text-dark-400 dark:text-darktext">
                        Nombre des Billets :
                     </span>
                     <div className="flex flex-row items-center gap-2">
                        <HiMinus
                           onClick={() => removeSpots()}
                           className="material-icons-round cursor-pointer rounded-md bg-gradient-to-r from-pink-600 to-red-400 p-1 text-darktext dark:text-darktext"
                        />
                        <input
                           type="number"
                           onChange={(e) => handleChange(e)}
                           value={tickets}
                           className="max-w-8 border-none bg-inherit p-1 text-center font-semibold text-dark-400 dark:text-darktext"
                        />
                        <HiPlus
                           disabled={available > 0 ? false : true}
                           onClick={() => addSpots()}
                           className="material-icons-round cursor-pointer rounded-md bg-gradient-to-r from-green-600 to-emerald-400 p-1 text-darktext dark:text-darktext "
                        />
                     </div>
                  </div>
                  <Button
                     className="bg-gradient-to-r from-indigo-600 to-blue-700 text-slate-200"
                     color="blue"
                     onClick={() => {
                        if (loggedUser) {
                           openTicketHolder()
                        } else {
                           openPopUp({
                              // alert: true,
                              title: 'Connexion Requise',
                              content:
                                 'Vous devez vous connecter à votre compte pour effectuer toute réservation de tickets, si vous n’avez pas de compte, veuillez vous inscrire.',
                              decline: {
                                 title: 'Annuler',
                                 // handler: closeParent,
                              },
                              accept: {
                                 title: 'Se Connecter',
                                 handler: () => {
                                    closePop()
                                    changeForm('connexion')
                                 },
                              },
                           })
                        }
                     }}
                  >
                     <span className="font-bold text-white  dark:text-slate-200">
                        {' '}
                        Réserver
                     </span>
                  </Button>
               </div>
            ) : (
               <TicketHolderInfo
                  back={() => setShowNameHolder(false)}
                  ticket={ticket}
                  event={event}
                  state={ticketHolder}
                  tickets={bookedTickets}
                  close={closeTicketHolder}
                  closeParent={closePop}
               />
            )}
         </CustomPopUp>
      </>
   )
}

function TicketHolderInfo({
   ticket,
   event,
   tickets,
   state,
   back,
   close,
   closeParent,
}) {
   const { loggedUser } = useSessionContext()
   const [email, setEmail] = useState(
      getLoggedUser() ? getLoggedUser().user.email : '',
   )
   const { changeForm } = useFormModalContext()
   const [response, error, isLoading, setPost] = usePost(
      apiLink + '/api/events/reserve_many',
   )
   const [checked, setChecked] = useState(true)
   const { openPopUp } = usePopUpContext()
   const { addToCart, cart } = useCartContext()
   const {
      register,
      handleSubmit,
      // formState: { errors },
   } = useForm()

   const { changeEvent } = useEventModalContext()

   const handlePost = (reservation) => {
      // console.log(reservation)
      // return
      let data = []
      const code =
         'KERSV' +
         Math.floor(Math.random() * 1000) +
         new Date().getMilliseconds().toString()
      reservation?.tickets?.forEach(({ spot, name }) => {
         data.push({
            event_id: reservation?.event.id,
            code: code,
            name_holder: name,
            ticket_category_id: reservation?.ticket.id,
            user_id: getLoggedUser().user.id,
            spot: spot,
            email: reservation?.email,
         })
      })

      setPost({ array: data })
   }
   const handleReserve = (reservation) => {
      // setReserve(true)
      openPopUp({
         title: 'Reservation',
         content:
            "Vous êtes sur le point d'effectuer une réservation pour la catégorie : " +
            ticket?.type,
         decline: {
            title: 'Annuler',
            // handler: closeParent,
         },
         accept: {
            title: 'Réserver',
            handler: () => {
               handlePost(reservation)
            },
         },
      })
   }

   useEffect(() => {
      if (response) {
         openPopUp({
            title: '✅ Réservation',
            content:
               "Votre réservation a été effectuée avec succès, vous recevrez mail de confirmation une fois votre reservation approuvé par l'organisateur de l’événement",
            default: {
               title: 'Voir Ma Reservation',
               handler: () => {
                  back && back()
                  closeParent && closeParent()
                  close && close()
                  changeEvent && changeEvent()
                  changeForm && changeForm('mes billets')
               },
            },
         })
      }
      if (error) {
         // console.log(error)
         openPopUp({
            title: "Erreur d'authentification",
            content: error.message,
            decline: {
               title: 'Essayer à nouveau',
            },
         })
      }
   }, [response, error])

   const handleAddToCart = (addToCart, cart) => {
      if (+ticket?.is_free === 1) {
         handleReserve({
            id: event.id + 'ttk' + ticket.id + Math.floor(Math.random() * 100),
            event: event,
            ticket: ticket,
            tickets: tickets,
            email: email,
         })
      } else {
         if (cart && cart.length > 0) {
            addToCart({
               id:
                  event.id +
                  'ttk' +
                  ticket.id +
                  Math.floor(Math.random() * 100),
               event: event,
               ticket: ticket,
               tickets: tickets,
               email: email,
            })
            closeParent()
            close()
         } else {
            openPopUp({
               title: 'Confirmation',
               content:
                  "Vos billets seront ajoutés au Panier, pour vous permettre d'acheter les billets des multiples Événements en même temps.",
               decline: {
                  title: 'Annuler',
                  // handler: closeParent,
               },
               accept: {
                  title: 'Continuer',
                  handler: () => {
                     closeParent()
                     close()
                     addToCart({
                        id:
                           event.id +
                           'ttk' +
                           ticket.id +
                           Math.floor(Math.random() * 100),
                        event: event,
                        ticket: ticket,
                        tickets: tickets,
                        email: email,
                     })
                  },
               },
            })
         }
      }
   }

   useEffect(() => {
      loggedUser && setEmail(loggedUser?.email)
   }, [loggedUser])
   return (
      // <CustomPopUp title={'Ticket Holder Info'} state={state} close={close}>
      <form
         onSubmit={handleSubmit((e) => e && handleAddToCart(addToCart, cart))}
         className="flex max-h-120 flex-col items-center  gap-2 overflow-y-scroll p-2"
      >
         <span className="text-center  text-dark-400 dark:text-darktext md:text-4">
            Veuillez saisir le Nom du Propriétaire de chaque Billet
         </span>
         <>
            {tickets.map((ticket, index) => (
               <TicketNameHolder
                  index={index + 1}
                  register={register}
                  key={'name_h' + ticket?.spot}
                  ticket={ticket}
               />
            ))}
         </>
         <span className="mt-2 border-t px-2 pt-2 text-center text-3 text-dark-400 dark:border-dark dark:text-darktext md:text-4">
            Veuillez saisir l'adresse mail sur le quel vous voudrez recevoir les
            détails sur la confirmation de votre réservation.
         </span>
         <div className="flex w-full flex-col items-center justify-center gap-4 px-4 md:px-8">
            <Email
               register="email"
               value={email}
               sendValue={setEmail}
               required
               type={'mail'}
               name="email"
               placeholder={'votre adresse mail'}
               setChecked={setChecked}
               className=" border-none bg-darktext p-1 text-center text-dark dark:bg-dark dark:text-darktext"
            />

            {ticket?.is_free === 1 ? (
               <Button
                  type="submit"
                  color="blue"
                  isProcessing={isLoading}
                  // onClick={}
                  disabled={checked}
                  className="flex w-full flex-row items-center gap-4 p-0"
               >
                  <div className="flex w-full flex-row items-center gap-4 p-0">
                     {/* <FaShoppingCart className="text-6" />{' '} */}
                     <span>Reserver maintenant</span>
                  </div>
               </Button>
            ) : (
               <Button
                  type="submit"
                  color="blue"
                  // onClick={}
                  disabled={checked}
                  className="flex w-full flex-row items-center gap-4 p-0"
               >
                  <div className="flex w-full flex-row items-center gap-4 p-0">
                     <FaShoppingCart className="text-6" />{' '}
                     <span>Ajouter au Panier</span>
                  </div>
               </Button>
            )}
         </div>
      </form>
      // {/* </CustomPopUp> */}
   )
}

function TicketNameHolder({ ticket, register, index }) {
   const setName = (n) => {
      ticket.name = n
   }
   return (
      <div className="flex w-full flex-col items-center justify-center gap-1 px-4 md:px-8">
         <span className="font-semibold text-dark-400 dark:text-darktext">
            Ticket #{index}
            {/* Place N° {ticket.spot} */}
         </span>
         <Input
            register={'ticket-' + ticket?.spot}
            min={5}
            value={ticket?.name}
            sendValue={setName}
            required
            placeholder={'Saisir le nom du propriétaire'}
            name={'ticketHolder' + ticket.id}
            type={'text'}
            className="w-full border-none bg-darktext text-center text-dark dark:bg-dark dark:text-darktext"
         />
      </div>
   )
}
