import { Button, TextInput } from 'flowbite-react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { HiEye, HiEyeOff, HiMail, HiPhone, HiUser } from 'react-icons/hi'
import { apiLink } from '../../utilis/ApiContext'
import { usePost } from '../../utilis/CustomHooks'

export default function Register({ handler, close, openPopUp, startSession }) {
   // const [checked, setChecked] = useState(true)
   const [response, error, isLoading, setPost] = usePost(
      apiLink + '/auth/register',
   )
   const {
      register,
      handleSubmit,
      formState: { errors },
      // watch
   } = useForm()
   const [showPass, setShowPass] = useState(false)
   const handleShowPass = () => {
      setShowPass(!showPass)
   }
   const checkInputs = () => {
      // console.log(10)
      return (
         errors.first_name ||
         errors.email ||
         errors.username ||
         errors.password ||
         errors.telephone
      )
      // return true
   }
   const handleClick = (data) => {
      setPost(data)
   }
   // useEffect(() => {
   //    setChecked(checkInputs())
   // }, [errors])

   useEffect(() => {
      if (response) {
         // console.log(response)
         // startSess
         startSession(response?.user, response?.token, response?.expiry)
         openPopUp({
            alert: true,
            title: 'Enregistrement effectué avec succès.',
            content: `Bienvenue sur KwetuEvent, @${response?.user?.username} ✨`,
            default: {
               title: '👊🏾',
               handler: close,
            },
         })
      }
      if (error) {
         // console.log(error)
         openPopUp({
            title: "Erreur d'enregistrement",
            content: error.message,
            decline: {
               title: 'Try Again',
            },
         })
      }
   }, [response, error])
   return (
      <div className="flex w-full flex-col items-center justify-center px-4 pb-4">
         <span className="material-icons-round text-12 font-medium text-dark dark:text-primary md:text-24">
            person
         </span>
         <div className="flex w-full flex-col gap-2 sm:gap-3">
            <TextInput
               rightIcon={HiUser}
               {...register('first_name', {
                  required: true,
                  minLength: {
                     value: 6,
                     message: 'champ invalide, min: 6 caractères',
                  },
               })}
               color={errors.first_name && 'failure'}
               id="name"
               type="text"
               placeholder="Votre nom"
               // value={email}
               // onChange={(event) => setEmail(event.target.value)}
               required
            />

            <TextInput
               rightIcon={HiMail}
               {...register('email', {
                  required: true,
                  pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,

                  // minLength
               })}
               color={errors.email && 'failure'}
               id="email"
               type="mail"
               placeholder="name@domain.com"
               // value={email}
               // onChange={(event) => setEmail(event.target.value)}
               required
            />
            <TextInput
               rightIcon={HiUser}
               {...register('username', {
                  required: true,
                  pattern: {
                     value: /^\S*$/,
                     message: "pas d'espaces sur le nom d'utilisateur",
                  },
                  minLength: {
                     value: 6,
                     message: 'champ invalide, min: 6 caractères',
                  },
               })}
               color={errors.username && 'failure'}
               id="last name"
               type="text"
               placeholder="Nom d'utilisateur"
               // value={email}
               // onChange={(event) => setEmail(event.target.value)}
               required
            />
            <div className="flex flex-row items-center gap-1">
               <TextInput
                  // rightIcon={HiLockClosed}
                  {...register('password', { required: true })}
                  color={errors.password && 'failure'}
                  id="password"
                  type={showPass ? 'text' : 'password'}
                  placeholder="Entrer le mot de Passe"
                  required
                  className="w-full"
               />
               {!showPass ? (
                  <HiEye
                     onClick={handleShowPass}
                     className=" cursor-pointer rounded-md border bg-bg p-2 text-11 text-gray-400 dark:border-gray-500 dark:bg-gray-700 dark:text-slate-200"
                  />
               ) : (
                  <HiEyeOff
                     onClick={handleShowPass}
                     className=" cursor-pointer rounded-md border bg-bg p-2 text-11 text-gray-400 dark:border-gray-500 dark:bg-gray-700 dark:text-slate-200"
                  />
               )}
            </div>
            <div className="flex flex-row items-center gap-1">
               <TextInput
                  // rightIcon={HiLockClosed}
                  {...register('confirm_password', { required: true })}
                  color={errors.confirm_password && 'failure'}
                  id="confirm-password"
                  type={showPass ? 'text' : 'password'}
                  placeholder="Confirmer le mot de Passe"
                  required
                  className="w-full"
               />
               {!showPass ? (
                  <HiEye
                     onClick={handleShowPass}
                     className=" cursor-pointer rounded-md border bg-bg p-2 text-11 text-gray-400 dark:border-gray-500 dark:bg-gray-700 dark:text-slate-200"
                  />
               ) : (
                  <HiEyeOff
                     onClick={handleShowPass}
                     className=" cursor-pointer rounded-md border bg-bg p-2 text-11 text-gray-400 dark:border-gray-500 dark:bg-gray-700 dark:text-slate-200"
                  />
               )}
            </div>

            <TextInput
               rightIcon={HiPhone}
               {...register('telephone', {
                  required: true,
                  // eslint-disable-next-line no-useless-escape
                  pattern: /^\+?\d[\d\s\.\-]+$/,
               })}
               color={errors.telephone && 'failure'}
               id="telephone"
               type="tel"
               placeholder="Telephone"
               // value={email}
               // onChange={(event) => setEmail(event.target.value)}
               required
            />
         </div>
         <div className="mt-2 flex w-full flex-col items-center justify-center gap-2">
            <Button
               disabled={
                  errors.first_name ||
                  errors.email ||
                  errors.username ||
                  errors.password ||
                  errors.telephone
               }
               isProcessing={isLoading}
               onClick={handleSubmit((e) => checkInputs && handleClick(e))}
               className="w-full bg-primary hover:bg-dark"
            >
               S'enregistrer
            </Button>
            {/* <span className="font-semibold text-dark dark:text-darktext">
               ou
            </span> */}
            <div className="flex w-full justify-center">
               <span
                  onClick={() => handler('connexion')}
                  className="cursor-pointer text-sm text-gray-600 hover:underline dark:text-slate-200"
               >
                  Retourner à la page de{' '}
                  <span className="font-semibold text-primary">Connexion</span>
               </span>
            </div>
         </div>
      </div>
   )
}
