import { Toast, Tooltip } from 'flowbite-react'
import { useState } from 'react'
import { BiCopy, BiShare } from 'react-icons/bi'
import { FaCopy } from 'react-icons/fa'
import {
   FacebookIcon,
   FacebookShareButton,
   TelegramIcon,
   TelegramShareButton,
   TwitterShareButton,
   WhatsappIcon,
   WhatsappShareButton,
   XIcon,
} from 'react-share'
import { useScreenWidth } from '../../utilis/CustomHooks/display'
import { copyTextToClipboard } from '../../utilis/functions'
import CustomPopUp from '../CustomPopUp/CustomPopUp'

export default function Share({ text, link, title }) {
   // const [show, setShow] = useState(false)
   const [share, setShare] = useState(false)
   const [isBig, isSmall] = useScreenWidth()
   const handleShare = () => {
      console.log(isBig)
      setShare(!share)
   }
   const [toast, setToast] = useState()
   // const [error, setError] = useState(false)
   const handleTost = (message) => {
      setToast(message)

      setTimeout(() => setToast(), 2000)
   }
   return (
      <>
         <div
            onClick={handleShare}
            className="cursor-pointer items-center justify-center rounded-lg bg-gradient-to-r from-indigo-600 to-blue-700 p-3"
         >
            <BiShare className=" text-5 text-slate-200" />
         </div>
         <CustomPopUp title={title} close={handleShare} state={share}>
            <div className="flex flex-col items-center gap-2 p-4">
               <span className="font-semibold text-gray-600 dark:text-slate-200">
                  Partager sur :
               </span>
               <div className="flex flex-row items-center gap-2">
                  <WhatsappShareButton
                     url={link}
                     title={text}
                     className="Demo__some-network__share-button"
                  >
                     <WhatsappIcon size={isSmall ? 32 : 48} round />
                  </WhatsappShareButton>
                  <TelegramShareButton
                     url={link}
                     title={text}
                     className="Demo__some-network__share-button"
                  >
                     <TelegramIcon size={isSmall ? 32 : 48} round />
                  </TelegramShareButton>
                  <FacebookShareButton
                     title={text}
                     url={link}
                     className="Demo__some-network__share-button"
                  >
                     <FacebookIcon size={isSmall ? 32 : 48} round />
                  </FacebookShareButton>
                  <TwitterShareButton
                     url={link}
                     title={text}
                     className="Demo__some-network__share-button"
                  >
                     <XIcon size={isSmall ? 32 : 48} round />
                  </TwitterShareButton>
                  <Tooltip content="Copier" color="auto" placement="top">
                     <div
                        onClick={() => {
                           copyTextToClipboard(link).then((val) => {
                              val && handleTost('Le lien a été copiée')
                           })
                        }}
                        className="flex items-center justify-center rounded-full bg-dark p-2 md:p-3"
                     >
                        <BiCopy className="cursor-pointer  md:text-6" />
                     </div>
                     {/* pjjhs */}
                     {/*  */}
                  </Tooltip>
                  {/* <TheToast toast={toast} /> */}
                  {toast && <TheToast toast={toast} />}
               </div>
            </div>
         </CustomPopUp>
      </>
   )
}

export function TheToast({ toast }) {
   return (
      <div className=" absolute z-10 mb-32 md-max:right-8">
         <Toast className="">
            <div className="inline-flex h-6 w-6 shrink-0 items-center justify-center rounded-lg bg-cyan-100 text-cyan-500 dark:bg-cyan-800 dark:text-cyan-200">
               <FaCopy className="h-5 w-5 p-1" />
            </div>
            <div className="ml-3 text-sm font-normal">
               {toast ? toast : 'Le lien a été copiée'}
            </div>
            <Toast.Toggle />
         </Toast>
      </div>
   )
}
