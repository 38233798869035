import 'material-icons/iconfont/round.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import WebsiteHome from './pages/Home'
import './styles/index.css'

import { useThemeMode } from 'flowbite-react'
import { useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { BrandCrashed } from './components/Brand'
import Layout from './components/Layout'
import Events from './pages/Events'
import Explore from './pages/Explore'

export default function App() {
   const { setMode, mode } = useThemeMode()
   useEffect(() => {
      handleThem(mode)
   }, [mode])

   const handleThem = (mode) => {
      const metaThemeColor = document.querySelector('meta[name="theme-color"]')
      metaThemeColor.content = mode === 'dark' ? '#1C2129' : '#EAEAEA'
   }

   useEffect(() => {
      window.onbeforeunload = confirmExit
      function confirmExit() {
         return 'Vous êtes sur le point de quitter KwetuEvent, le voulez-vous vraiment?'
      }
   }, [])

   useEffect(() => {
      const prefersDarkScheme = window.matchMedia(
         '(prefers-color-scheme: dark)',
      )
      const prefersLightScheme = window.matchMedia(
         '(prefers-color-scheme: light)',
      )

      const updateColorScheme = () => {
         if (prefersDarkScheme.matches) {
            setMode('dark')
         } else if (prefersLightScheme.matches) {
            setMode('light')
         } else {
            setMode('light')
         }
      }

      updateColorScheme()

      prefersDarkScheme.addEventListener('change', updateColorScheme)
      prefersLightScheme.addEventListener('change', updateColorScheme)

      return () => {
         prefersDarkScheme.removeEventListener('change', updateColorScheme)
         prefersLightScheme.removeEventListener('change', updateColorScheme)
      }
   }, [])

   return (
      <ErrorBoundary fallback={<BrandCrashed crashed={true} />}>
         {/* <ScanModalProvider> */}
         <Router>
            <Layout>
               <Routes>
                  <Route path="/events/:id" element={<Events />} />
                  <Route path="/explore" element={<Explore />} />
                  <Route path="*" element={<WebsiteHome />} />
               </Routes>
            </Layout>
         </Router>
      </ErrorBoundary>
   )
}
