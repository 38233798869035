import { Toast } from 'flowbite-react'
import { useEffect, useState } from 'react'
import { HiStatusOffline, HiStatusOnline } from 'react-icons/hi'
import { useApiContext } from '../../utilis/ApiContext'

export default function InternetState() {
   const [showToast, setShowToast] = useState(false)
   const { refreshAll } = useApiContext()
   const [online, setIsOnline] = useState()
   //    const [isOnline, setIsOnline] = useState(navigator.onLine)

   const handleOnlineStatusChange = () => {
      setIsOnline(navigator.onLine)
      setShowToast(true)

      navigator.onLine && setTimeout(() => setShowToast(false), 3000)
   }

   useEffect(() => {
      if (!navigator.onLine) {
         handleOnlineStatusChange()
      }

      // Event listeners for online/offline events
      window.addEventListener('online', handleOnlineStatusChange)
      window.addEventListener('offline', handleOnlineStatusChange)

      // Cleanup: remove event listeners when component is unmounted
      return () => {
         window.removeEventListener('online', handleOnlineStatusChange)
         window.removeEventListener('offline', handleOnlineStatusChange)
      }
   }, [])

   useEffect(() => {
      if (online) {
         refreshAll()
         // console.log('online')
      }
      // console.log('internet state')
      online && refreshAll()
   }, [online, navigator.onLine])

   return (
      showToast && (
         <div className="fixed left-0 z-990 flex w-screen items-center justify-center p-2 md:top-0 md:p-4 md-max:bottom-0">
            {online ? (
               <Online close={setShowToast} />
            ) : (
               <Offline close={setShowToast} />
            )}
         </div>
      )
   )
}

function Online({ close }) {
   return (
      <Toast className=" w-full max-w-screen-sm border-2 border-green-400 p-2 pr-4 dark:bg-dark-400">
         <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-cyan-100 text-cyan-500 dark:bg-cyan-800 dark:text-cyan-200">
            <HiStatusOnline className="h-5 w-5" />
         </div>

         <div className="ml-3 text-sm font-normal text-green-700 dark:text-green-400">
            Vous êtes de nouveau connecté à internet.
         </div>

         <Toast.Toggle onDismiss={() => close(false)} />
      </Toast>
   )
}
function Offline({ close }) {
   return (
      <Toast className=" w-full max-w-screen-sm border-2 border-red-800 p-2 pr-4 dark:bg-dark-400">
         <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-red-800 text-red-500 dark:text-red-400">
            <HiStatusOffline className="h-5 w-5" />
         </div>

         <div className="ml-3 text-sm font-normal text-red-600">
            Vous n'êtes pas connecté à internet.
         </div>

         <Toast.Toggle onDismiss={() => close(false)} />
      </Toast>
   )
}
