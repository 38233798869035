import { FaFacebook, FaInstagram, FaWhatsapp } from 'react-icons/fa'
import { LuExternalLink } from 'react-icons/lu'
import { useFormModalContext, useSessionContext } from '../../utilis/Context'
import { LogoV2 } from '../Brand/Logo'
import { PaymentMethods } from '../Cart/PaymentMethod'
export default function Footer() {
   const { changeForm } = useFormModalContext()
   const { loggedUser } = useSessionContext()
   return (
      <div className="m4 mb-19 flex w-full max-w-screen-xl flex-col items-center justify-between bg-bg p-4 text-3 text-gray-600 dark:bg-dark dark:text-slate-400  sm:rounded-xl md:text-4 lg:mb-5">
         <div className="flex w-full flex-col items-start gap-6 p-5 sm:flex-row">
            <div className="flex w-full flex-row items-start gap-6 sm:w-9/12">
               <div className="hidden w-4/12 flex-col justify-between gap-2 lg:flex">
                  <LogoV2 />
                  <div className="flex w-full flex-col items-center pt-4 text-center">
                     <span className="font-light">from</span>
                     <a
                        target="_blank"
                        className="flex items-center gap-2 text-5 font-semibold hover:text-primary-500 dark:text-slate-200"
                        href="http://kwetutech.com"
                        rel="noreferrer"
                     >
                        <span>KwetuTech</span> <LuExternalLink />
                     </a>
                  </div>
               </div>
               <div className="flex w-6/12 flex-col gap-2 lg:w-4/12">
                  <span className="font-bold dark:text-slate-300 lg:text-5">
                     Découvrir KwetuEvent
                  </span>
                  {/* <hr className="w-full border-slate-200 dark:border-gray-600" /> */}
                  <div className="flex w-full flex-col gap-1 text-start">
                     <span
                        className="cursor-pointer hover:font-semibold dark:hover:text-slate-200 "
                        onClick={() => changeForm('contactez nous')}
                     >
                        Créez votre Billetterie
                     </span>
                     <span
                        className="cursor-pointer hover:font-semibold dark:hover:text-slate-200 "
                        onClick={() => changeForm('mes billets')}
                     >
                        Mes Tickets
                     </span>
                     <span
                        className="cursor-pointer hover:font-semibold dark:hover:text-slate-200 "
                        onClick={() =>
                           loggedUser
                              ? changeForm('mon compte')
                              : changeForm('connexion')
                        }
                     >
                        Mon Compte
                     </span>
                     <span
                        className="cursor-pointer hover:font-semibold dark:hover:text-slate-200 "
                        onClick={() => changeForm('contactez nous')}
                     >
                        Contacts
                     </span>
                  </div>
               </div>
               <div className="flex w-6/12 flex-col gap-2 lg:w-4/12">
                  <span className="font-bold dark:text-slate-300 lg:text-5">
                     Mentions légales
                  </span>
                  {/* <hr className="w-full border-slate-200 dark:border-gray-600" /> */}
                  <div className="flex w-full flex-col gap-1 text-start">
                     <a
                        className="flex cursor-pointer flex-row items-center gap-2 hover:font-semibold dark:hover:text-slate-200 "
                        href="/cgv.html"
                        target="_blank"
                        rel="noopener noreferrer"
                     >
                        Conditions de vente <LuExternalLink />
                     </a>
                     <a
                        className="flex cursor-pointer flex-row items-center gap-2 hover:font-semibold dark:hover:text-slate-200 "
                        href="/cgu.html"
                        target="_blank"
                        rel="noopener noreferrer"
                     >
                        Générales d'Utilisation <LuExternalLink />
                     </a>
                     <a
                        className="flex cursor-pointer flex-row items-center gap-2 hover:font-semibold dark:hover:text-slate-200 "
                        href="https://kwetutech.com"
                        target="_blank"
                        rel="noopener noreferrer"
                     >
                        Company <LuExternalLink />
                     </a>
                  </div>
               </div>
            </div>
            <div className="flex w-full flex-col gap-4 sm:w-3/12">
               <span className="font-bold dark:text-slate-300 lg:text-5">
                  Suivez nous sur :
               </span>
               {/* <hr className="w-full border-bg dark:border-gray-600" /> */}
               <div className="flex w-full flex-row  gap-2 text-start">
                  <a
                     href="https://www.facebook.com/share/1Q9g3aD3KK/?mibextid=wwXIfr"
                     target="_blank"
                     rel="noopener noreferrer"
                  >
                     <FaFacebook className="cursor-pointer text-8 hover:text-primary-500 dark:hover:text-gray-200" />
                  </a>
                  <a
                     href="https://www.instagram.com/kwetuevent?igsh=NndlZ2oydGxnc3ds&utm_source=qr"
                     target="_blank"
                     rel="noopener noreferrer"
                  >
                     <FaInstagram className="cursor-pointer text-8 hover:text-pink-500 dark:hover:text-gray-200" />
                  </a>
                  <a
                     href="https://whatsapp.com/channel/0029Vb20j47EKyZMsOJiCE0U"
                     target="_blank"
                     rel="noopener noreferrer"
                  >
                     <FaWhatsapp className="cursor-pointer text-8 hover:text-green-500 dark:hover:text-gray-200" />
                  </a>
                  {/* <a
                     href="https://tiktok.com/kwetuevent"
                     target="_blank"
                     rel="noopener noreferrer"
                  >
                     <FaTiktok className="cursor-pointer text-8 hover:text-green-500 dark:hover:text-gray-200" />
                  </a> */}
               </div>
               <PaymentMethods />
            </div>
            <div className="flex w-full flex-col justify-center gap-2 sm:hidden">
               <div className="flex w-full flex-col items-center pt-4 text-center">
                  <span className="font-light">from</span>
                  <a
                     target="_blank"
                     className="flex items-center gap-2 text-5 font-semibold hover:text-primary-500 dark:text-gray-500 hover:dark:text-slate-200"
                     href="http://kwetutech.com"
                     rel="noreferrer"
                  >
                     <span>KwetuTech</span> <LuExternalLink />
                  </a>
               </div>
            </div>
         </div>
         {/* <div className="w-full px-5">
            <hr className="w-full border-slate-200 dark:border-gray-600" />
         </div> */}
      </div>
   )
}
